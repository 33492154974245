import React, { useEffect, useState } from "react";
import ScoreCard from "./ScoreCard";
import "./Sport.css";
import OpenMarkeet from "./OpenMarkeet";
import LiveTvSection from "./LiveTvSection";
import MatchOdds from "./MatchOdds";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { LastBetDetails } from "../../Redux/Slices/LastbetSlice";
import { fetchDashbetHistory } from "../../Redux/Slices/dashbetHistorySlice";
import SportModal from "./SportModal";
const Sport = () => {
  // setbetType={setbetType}
  // setmarketType={setmarketType}
  // setbetDetails={setbetDetails}
  // betDetails={betDetails}
  // betType={betType}
  // marketType={marketType}
  // countryName={countryName}
  // realtimeData={realtimeData}
  // setRealtimeData={setRealtimeData}
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  useEffect(() => {
    dispatch(fetchuserBalance({ id: uid, token }));
    dispatch(LastBetDetails({ userId: uid, id: id, token }));
    dispatch(fetchDashbetHistory({ userId:uid, token }));
  }, []);
  return (
    <div className="col-lg-10  pt-1 betting-tv-body " style={{overflowX:"hidden"}}>
      <div className="row px-1 m-0">
        <div className="col-lg-12  px-2 score-card " style={{overflowX:"hidden"}}>
          {/* <ScoreCard /> */}
          <LiveTvSection />
          <ScoreCard />
          <OpenMarkeet />
          {/* <MatchOdds/> */}
        </div>
        <div className="col-4">
        </div>
        {/* {modal && <ShowModal />} */}
        {/* <SportModal /> */}
      </div>
    </div>
  );
};
export default Sport;
