import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchIframeDetails } from "../../Redux/Slices/IframeSlice";

export default function Iframe() {
  const { Id } = useParams();
  console.log("id", Id);
  // const { Id } = useParams()
  const dispatch = useDispatch();
  const { iframe, loading, error } = useSelector(
    (state) => state.iframeDetails
  );

  useEffect(() => {
    dispatch(fetchIframeDetails({ id: Id }));
  }, [dispatch, Id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!iframe || iframe.length === 0) {
    return <div className="w-100 h-100">No iframe data available</div>;
  }
  return (
    <>
      <div className="" style={{ height: "100vh" }}>
        <iframe
          height="100%"
          width="100%"
          src={String(iframe[0]?.iframe)}
          title=""></iframe>
      </div>
    </>
  );
}
