import React, { useEffect } from "react";
import { useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./Aviator.css";
import { CiCirclePlus } from "react-icons/ci";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import AviatorMain from "./AviatorMain";
import { io } from "socket.io-client";
import Progressbar from "./Progressbar";
import FlewAway from "./FlewAway";
import AviatorButton from "./AviatorButton";
import { API } from "../../API/API";
import { useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { toast } from "react-toastify";
import { Loader } from "./Loader";

const Aviator = () => {
  const [loader, setLoader] = useState(true);
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  // let uId = sessionStorage.getItem("userName");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [aviatorData, setAviatorData] = useState([]);
  const [status, setStatus] = useState(3);
  // const [betplace, setBetplace] = useState("");
  // const [period, setPeriod] = useState("");
  // const [amount, setAmount] = useState(0);
  const [betHistory, setBetHistory] = useState([]);
  const SOCKET_URL = "https://greenlebel-api.nakshtech.info/";
  // console.log("data");
  // useEffect(() => {
  //   // console.log("Aviator_socket");
  //   const AviatorSocket = io(SOCKET_URL);
  //   AviatorSocket.emit("GetAviatorGameSocket");

  //   AviatorSocket.on("GetAviatorGameSocket_FromAPI", (data) => {
  //     const parse1 = JSON.parse(data);
  //     // console.log("socket_Data-->", parse1);

  //     //   const parsedData = data.toString().substring(0, 4);
  //     // console.log("Aviator data-->", parsedData.timer);
  //     setStatus(parse1.status);
  //     setAviatorData(parse1);
  //     if (parse1.status === 0) {
  //       setLoader(false);
  //     }
     
  //   });

  //   // const interval = setInterval(() => {
  //   //   setStatus((prevStatus) => {
  //   //     if (prevStatus === 2) {
  //   //       return 0;
  //   //     } else {
  //   //       return prevStatus + 1;
  //   //     }
  //   //   });
  //   // },10000);

  //   return () => {
  //     // clearTimeout(interval);
  //     AviatorSocket.disconnect();
  //   };
  // }, []);



    useEffect(() => {
    // console.log("Aviator_socket");
    const AviatorSocket = io(SOCKET_URL);
    AviatorSocket.emit("GetAviatorGameSocket",userId);

    AviatorSocket.on("GetAviatorGameSocket_FromAPI", (data) => {
      const parse1 = JSON.parse(data);
      // console.log("socket_Data-->", parse1);

      //   const parsedData = data.toString().substring(0, 4);
      // console.log("Aviator data-->", parsedData.timer);
      setStatus(parse1.status);
      setAviatorData(parse1);
      if (parse1.status === 0) {
        setLoader(false);
      }
     
    });

    // const interval = setInterval(() => {
    //   setStatus((prevStatus) => {
    //     if (prevStatus === 2) {
    //       return 0;
    //     } else {
    //       return prevStatus + 1;
    //     }
    //   });
    // },10000);

    return () => {
      // clearTimeout(interval);
      AviatorSocket.disconnect();
    };
  }, []);


  

  // console.log("hello", aviatorData.status);

  const AvaitorbetHistory_API = async () => {
    try {
      let res = await API.post(
        `/AviatorBetHistory`,
        { uid: userId, Gameid: "2", Limit: "10" },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("betHistory", res);
      const data = res.data.data[0];
      // console.log("betHistory->", data);
      if (data.length > 0) {
        setBetHistory(data);
      }
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    AvaitorbetHistory_API();
  }, []);

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home ">
          <div className="col-lg-8 p-2">
            <div className="Aviator-main-div pt-1 p-2">
              <div className="aviator-h-div d-flex px-2 pt-1">
                <h3>Avaitor</h3>
                <p className="mt-2 px-1">({aviatorData.period})</p>
              </div>
              <div className="small-btns-div d-flex">
                {betHistory.map((ele, index) => (
                  <div className="small-g-btn mx-1" key={index}>
                    {ele.Multiplayer}x
                  </div>
                ))}
              </div>
              {loader === true ? (
                <Loader />
              ) : (
                <div className="canvas-main-div mt-3 mx-auto">
                  {status === 0 && (
                    <Progressbar betTime={aviatorData.betTime} />
                  )}
                  <div>
                    {status === 1 && (
                      <div>
                        <AviatorMain aviatorData={aviatorData} />
                        <h1 className="result-bet">{aviatorData.timer}x</h1>
                      </div>
                    )}
                  </div>
                  {status === 2 && <FlewAway result={aviatorData.timer} />}
                </div>
              )}
            </div>
            <AviatorButton
              // status={aviatorData.status}
              // periodNumber={aviatorData.period}
              // result={aviatorData.timer}

              socketData={aviatorData}
            />
          </div>

          <RightSidebar />
        </div>
      </div>
    </>
  );
};

export default Aviator;
