import React, { useEffect, useState } from "react";
import "./Deposit.css";
import { API } from "../../API/API";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Contract, {
  AGC_Token_Abi,
  AGC_Token_Address,
  USDTAddress,
  USDT_Abi,
  battlemoney_ABI,
  battlemoney_Address,
} from "../../Utility/Contract/Contract";
import Web3 from "web3";
import { loadWeb3 } from "../../Utility/Contract/web";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Slices/authSlice";

let count1 = 0;
let count2 = 0;
let count3 = 0;
let count4 = 0;
let count5 = 0;
let count6 = 0;
let sum = 0;

const Deposit = () => {
  const user = sessionStorage.getItem("user");
  const token = sessionStorage.getItem("token");

  const userId = sessionStorage.getItem("userData");
  let UserName = sessionStorage.getItem("userName");
  console.log("UserName", UserName);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [liveRate, setliveRate] = useState(0);
  let [amount, setamount] = useState(0);
  let [meticmultiplyablevalue, setmeticmultiplyablevalue] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [valueBNB, setvalueBNB] = useState(0);
  let [valueCoin, setvalueCoin] = useState(0);
  let [balanceUle, setbalanceUle] = useState(0);
  let [TrxBalance, setTrxBalance] = useState(0);
  let [spiner, setspiner] = useState(false);
  let [Live_Price, setLive_Price] = useState(0);
  const [package_plan, setpackage_plan] = useState("USDT");
  const [Wallet_Address, setWallet_Address] = useState("");

  const [loading, setloading] = useState(false);
  let navigation = useNavigate();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc === "No Wallet") {
        toast.error(acc);
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const UleBalance = async () => {
    try {
      let acc = await loadWeb3();
      if (acc) {
        setloading(true);
        let contract = await new window.web3.eth.Contract(
          USDT_Abi,
          USDTAddress
        );
        let result = await contract.methods.balanceOf(acc).call();
        // result = parseInt(result)
        result = window.web3.utils.fromWei(result);
        setbalanceUle(result);
        let contractOf_AGC = await new window.web3.eth.Contract(
          AGC_Token_Abi,
          AGC_Token_Address
        );
        let BalanveOf_AGC = await contractOf_AGC.methods.balanceOf(acc).call();
        BalanveOf_AGC = window.web3.utils.fromWei(BalanveOf_AGC.toString());
        setTrxBalance(BalanveOf_AGC);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  let webSupply1 = new Web3("https://bsc-mainnet.public.blastapi.io");
  const getValue_BNB = async () => {
    try {
      let res = await API.post(
        `/getbetawallet`,
        { username: UserName },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("usdt", res);
      res = res.data.data[0][0].Wallet_Address;
      setWallet_Address(res);
      console.log("getbetawallet", res);
      if (amount != 0) {
        let web3 = window.web3;
        let contractOf = new web3.eth.Contract(
          battlemoney_ABI,
          battlemoney_Address
        );
        if (package_plan == "USDT") {
          let Amount_USDT = await contractOf.methods.getPrice(amount).call();

          Amount_USDT = window.web3.utils.fromWei(Amount_USDT[1].toString());
          setLive_Price(Amount_USDT);
        } else {
          let Amount_AGC = await contractOf.methods.getPrice(amount).call();
          Amount_AGC = window.web3.utils.fromWei(Amount_AGC[0].toString());
          setLive_Price(parseFloat(Amount_AGC).toFixed(2));
        }
      }
    } catch (e) {
      console.log("Error While Calling GetValue API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const buyToken = async () => {
    let acc = await loadWeb3();
    if (acc == "No Wallet") {
      toast.error("No Wallet");
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect BSC Scan Network");
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase())
        if (amount != 0) {
          if (package_plan == "USDT") {
            if (balanceUle < amount) {
              toast.error("Insufficiant USDT Balance");
            } else {
              setspiner(true);

              let amountValue = window.web3.utils.toWei(amount.toString());
              const gasPrice = await window.web3.eth.getGasPrice();
              const approveBlock = window.web3.eth.getBlock("latest");

              try {
                let contract = await new window.web3.eth.Contract(
                  battlemoney_ABI,
                  battlemoney_Address
                );
                let contractToken = await new window.web3.eth.Contract(
                  USDT_Abi,
                  USDTAddress
                );

                let approveCall = await contractToken.methods
                  .approve(battlemoney_Address, amountValue)
                  .send({
                    from: acc,
                    gasLimit: approveBlock.gasLimit,
                    gasPrice: await window.web3.eth.getGasPrice(),
                  });
                toast.success("Approved Succesfull");
                let sellCall = await contract.methods.buyWithUSDT(amount).send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });

                let res = await API.post(
                  "buycoin",
                  {
                    accountnumber: acc,
                    BuyType: package_plan,
                    usdamount: amount,
                    tokenamount: Live_Price,
                    trx: sellCall.transactionHash,
                  },
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );
                console.log("buycoin", res.data);
                toast.success("Transection Succesfull");
                if (res.data.data === "Successfully Buy") {
                  toast.success("Deposit Successfull");
                  window.location.href = "./Dashboard/Cricket/4";
                  setspiner(false);
                }

                setspiner(false);
              } catch (err) {
                console.log("error while calling fuction sell", err);
                setspiner(false);
              }
            }
          } else {
            if (TrxBalance < Live_Price) {
              toast.error("Insufficiant AGV Token ");
            } else {
              setspiner(true);
              let amountValue = window.web3.utils.toWei(amount.toString());
              const gasPrice = await window.web3.eth.getGasPrice();

              try {
                let contract = await new window.web3.eth.Contract(
                  battlemoney_ABI,
                  battlemoney_Address
                );
                let contractToken = await new window.web3.eth.Contract(
                  AGC_Token_Abi,
                  AGC_Token_Address
                );

                let approveCall = await contractToken.methods
                  .approve(battlemoney_Address, amountValue)
                  .send({ from: acc, gasPrice: gasPrice });
                toast.success("Approved");
                let sellCall = await contract.methods
                  .buyWithAGC(amount)
                  .send({ from: acc, gasPrice: gasPrice });

                let res = await API.post(
                  "buycoin",
                  {
                    accountnumber: acc,
                    BuyType: package_plan,
                    usdamount: amount,
                    tokenamount: Live_Price,
                    trx: sellCall.transactionHash,
                  },
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                );

                console.log("buycoin", res.data);
                toast.success("Transection Succesfull");
                if (res.data.data === "Successfully Buy") {
                  toast.success("Deposit Successfull");
                  // window.location.href = "./Dashboard/Cricket/4";
                  setspiner(false);
                }
                setspiner(false);
              } catch (e) {
                console.log("error while calling fuction sell", e);
                setspiner(false);
                if (
                  e.response.data.showableMessage ===
                  "Please login to access this resource"
                ) {
                  sessionStorage.clear();
                  dispatch(logoutUser());
                  navigate("/");
                }
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
        }
      else {
        toast.error("Invalid Wallet address");
      }
    }

    // setloader(false)
  };

  const fifty = async (value) => {
    if (value == 10) {
      setmeticmultiplyablevalue(value);
      setamount("");
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count1 = count1 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 20) {
      setmeticmultiplyablevalue(value);
      setamount("");
      count1 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count2 = count2 + 1;

      sum = sum + value;
      setamount(sum);
      //  setamount(value * count2);
    } else if (value == 50) {
      setmeticmultiplyablevalue(value);
      setamount("");
      count1 = 0;
      count2 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      count3 = count3 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 100) {
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count5 = 0;
      count6 = 0;
      setmeticmultiplyablevalue(value);
      setamount("");
      count4 = count4 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 500) {
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count6 = 0;
      setmeticmultiplyablevalue(value);
      setamount("");
      count5 = count5 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 1000) {
      setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count2 = 0;
      count3 = 0;
      count4 = 0;
      count5 = 0;
      count6 = count6 + 1;

      sum = sum + value;
      setamount(sum);
    } else if (value == 0) {
      setamount("");
      setmeticmultiplyablevalue(value);
      count1 = 0;
      count3 = 0;
      count2 = 0;
      count4 = 0;
      count5 = 0;
      count6 = 0;
      sum = 0;
      setamount(sum);
      setvalueCoin(sum);
      setLive_Price(sum);
    }

    // setamount(value * count);
  };

  const Deposit_API = async () => {
    try {
      let res = await API.post(
        "buycoin",
        {
          accountnumber: "",
          BuyType: package_plan,
          usdamount: amount,
          tokenamount: Live_Price,
          trx: "",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("Deposit_API", res);
    } catch (e) {
      console.log("Somthing Error in Deposit_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getValue_BNB();
  }, [amount, package_plan]);

  useEffect(() => {
    walletConnected();
    UleBalance();
  }, []);

  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 px-2 p-1">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Deposit USDT</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card deposit-card mg-lg-4 mt-2 col-lg-6">
                {/* <div className="mb-0 d-flex">
                  <p className="step-1 ms-3 ">STEP 1:</p>
                  <p className="inst mb-0 ms-3">
                    Transfer the amount below given scaner
                  </p>
                </div> */}
                <div className="mb-0 d-flex">
                  {/* <p className="step-1 ms-3 ">STEP2:</p>
                  <p className=" inst mb-0 ms-3">
                    Enter wallet address amount and trasaction id below and
                    upload the transaction slip{" "}
                  </p> */}
                  <div className="card-header buy_hea col-lg-12">
                    {/* <h2 className="text-white text-start buy_header_h">
                      Deposit
                    </h2> */}
                    <h5 className="text-white text-start">
                      Available USDT Balance : {balanceUle} USDT
                    </h5>
                    <h5 className="text-white text-start">
                      Available AGC Token Balance : {TrxBalance} AGC Token
                    </h5>
                    {/* <h5 className="text-white text-start">
                              Live Rate :{" "}
                              <span>
                                <input
                                  type="text"
                                  value={`1 CNX =${liveRate} USD`}
                                  className="text-dark card_in w-50"
                                />
                              </span>{" "}
                            </h5> */}
                  </div>
                </div>

                <div className="px-4">
                  {/* <form>
                    <div className="row mt-2">
                      <div className="col-md-6 px-1">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            (Pay From)Wallet Address
                          </label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter Wallet Address"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 px-1">
                        <div className="form-group">
                          <label for="exampleInputEmail1">Amount</label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter  Amount"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 px-1">
                        <div className="form-group">
                          <label for="exampleInputEmail1">Transaction ID</label>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter Transaction ID"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 px-1">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            Upload Transaction Slip
                          </label>
                          <input
                            type="file"
                            className="form-control mt-2"
                            // id="exampleInputEmail1"
                            // aria-describedby="emailHelp"
                            // placeholder="upload"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn deposit-f-btn mt-lg-4 mt-2  "
                    >
                      Add Balance
                    </button>
                  </form> */}

                  <div className="mt-2 mb-2 d-flex justify-content-center">
                    <button
                      className={
                        package_plan == "USDT"
                          ? "btn input_btn"
                          : "active btn input_btn"
                      }
                      onClick={() => setpackage_plan("USDT")}>
                      USDT
                    </button>
                    <button
                      className={
                        package_plan == "AGCToken"
                          ? "btn input_btn mx-2"
                          : "active btn input_btn mx-2"
                      }
                      onClick={() => setpackage_plan("AGCToken")}>
                      AGC Token
                    </button>
                  </div>
                  <div className="card-body">
                    {connected == "Wallet is Connected" ? (
                      <h6
                        className="para text-start mb-2"
                        style={{ color: "white" }}>
                        {connected}
                      </h6>
                    ) : (
                      <h6 className="para text-start" style={{ color: "red" }}>
                        {connected}
                      </h6>
                    )}

                    <div className="input_buy">
                      <input
                        type="text"
                        className="card_in text-dark"
                        value={amount}
                        readOnly
                      />{" "}
                      <span className="text-white mt-2 fs-5 ms-1">Amount</span>
                    </div>

                    <div className="input_buy mt-3">
                      <input
                        type="text"
                        className="card_in text-dark"
                        value={Live_Price}
                        readOnly
                      />{" "}
                      <span className="text-white mt-2 fs-5 ms-1">
                        {package_plan == "USDT" ? "USDT" : "AGC Token"}
                      </span>
                    </div>
                    <div className="input_buy mt-3">
                      <input
                        type="text"
                        className="card_in text-dark"
                        value={amount * 90}
                        readOnly
                      />{" "}
                      <span className="text-white mt-2 fs-5 ms-1">PTS</span>
                    </div>
                    {/* <div className="input_buy mt-3">
                  <input type="text" className='card_in text-dark' value={valueBNB} /> <span className='text-white mt-2 fs-5 ms-1'>BNB Value</span>
                </div> */}

                    <div className="row mt-3 ">
                      <div className="col-lg-4 col-4 mb-2">
                        <button
                          className="btn input_btn"
                          onClick={() => {
                            fifty(10);
                          }}>
                          +10$
                        </button>
                      </div>
                      <div className="col-lg-4 col-4">
                        <button
                          className="btn input_btn"
                          onClick={() => {
                            fifty(20);
                          }}>
                          +20$
                        </button>
                      </div>
                      <div className="col-lg-4 col-4">
                        <button
                          className="btn input_btn"
                          onClick={() => {
                            fifty(50);
                          }}>
                          +50$
                        </button>
                      </div>
                      <div className="col-lg-4 col-4">
                        <button
                          className="btn input_btn"
                          onClick={() => {
                            fifty(100);
                          }}>
                          +100$
                        </button>
                      </div>
                      <div className="col-lg-4 col-4">
                        <button
                          className="btn input_btn"
                          onClick={() => {
                            fifty(500);
                          }}>
                          +500$
                        </button>
                      </div>
                      <div className="col-lg-4 col-4">
                        <button
                          className="btn input_btn"
                          onClick={() => {
                            fifty(1000);
                          }}>
                          +1000$
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn input_btn mt-3"
                      onClick={() => {
                        fifty(0);
                      }}>
                      Reset
                    </button>
                    <div
                      className="d-flex justify-content-center mt-3"
                      style={{ cursor: "pointer" }}>
                      <div className="buy_token" onClick={() => buyToken()}>
                        {spiner ? (
                          <>
                            <div
                              className="spinner-border spinnerload"
                              role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* <img src={logo} alt="" /> */}
                            <h4
                              className="text-white"
                              onClick={() => Deposit_API()}>
                              Deposit
                            </h4>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
