import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://greenlebel-api.nakshtech.info/",
});

export const fetchIframeDetails = createAsyncThunk(
  "iframe/fetchIframeDetails",
  async ({ id }) => {
    try {
      const response = await API.get(`/GetIframeByGameid?gameid=${id}`);
      return response.data.data[0];
    } catch (error) {
      throw error;
    }
  }
);

const iframeSlice = createSlice({
  name: "iframeDetails",
  initialState: {
    iframe: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIframeDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIframeDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.iframe = action.payload;
      })
      .addCase(fetchIframeDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ? action.error.message : "Unknown error";
      });
  },
});

export default iframeSlice.reducer;
