import React from "react";
import MatchesDetail from "../MatchesDetail/MatchesDetail";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./Home.css";
import PlayTabs from "../Playtab/Playtab";
import Cricket from "./MainBody";

const Home = () => {
  return (
    <div className=" home" style={{overflowX:"hidden"}}>
      <div className="col-md-12 ">
      <PlayTabs />
         {/* <MatchesDetail /> */}
        <RightSidebar /> 
        {/* <Cricket /> */}
      </div>
    </div>
  );
};

export default Home;
