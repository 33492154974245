import { memo } from "react";
import { MdCancel } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const RulesModal = ({  closeFunction }) => {
  const { id } = useParams();

  const {rules}=useSelector((state)=>state.rulesDetails)
   let item=rules.find((ele)=>ele.Gameid==id)
  return (
    <>
      <div
        onClick={() => {
          closeFunction(false);
        }}
        className="Results-modal-wrapper"
      ></div>
      <div 
      className="rule-modal-container bg-dark"
      >
        <div className=" d-flex justify-content-between pt-2">
          <div className="ms-2">Rules</div>
          <MdCancel
            className="cross-icon-result me-2"
            onClick={() => {
              closeFunction(false);
            }}
          />
        </div>
        <div>
          <div className="d-flex justify-content-center mt-2">
            <img src={item?.images}  alt="Images"  style={{width:'100%',height:'300px'}}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(RulesModal);
