// BetHeader.js
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'; // Import NavLink from 'react-router-dom'
import Marquee from 'react-fast-marquee';
import Logo from "../../Media/login_logo.png";
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';

import "./Search.css";
import "./Navbar.css"
import DropdownId from "./Dropdown";
import { Link } from "react-router-dom";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


import { BsFillPersonFill } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdLeaderboard } from "react-icons/md";
import { IoMdSwap } from "react-icons/io";
import { FaCreditCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { IoMdTrendingUp } from "react-icons/io";
import { MdOutlineLegendToggle } from "react-icons/md";
import { FaKey } from "react-icons/fa";
import StakeSettingModal from "./StakeSettingModal";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import PlayTabs from '../Playtab/Playtab';


export const Navbar1 = () => {
    const { userBalance } = useSelector((state) => state.userBalanceData);
  const uid = sessionStorage.getItem("userData");
  useEffect(() => {
    dispatch(fetchuserBalance({ id: uid, token }));
  }, []);
    const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(window.location.pathname);
  const displayModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
    const dispatch = useDispatch();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(logoutUser());
  };
  const token = sessionStorage.getItem("token");
  useEffect(() => {
    // Update active link on route change

    const path = location.pathname;

    if (path === "/Home") {
      setActive("/Home");
    } else if (path === "/InPlay") {
      setActive("/InPlay");
    } else if (path === "/LiveCasino") {
      setActive("/LiveCasino");
    }
  }, [location.pathname]);
const Navigates = useNavigate();
const handleToHome=()=>{
  return Navigates("/Home");
  
}
    return (
        <>
            <div className='cont-head' >
            <div className="main-header" >
                <div className='left-section-header' >
                <div>
                    <HomeIcon onClick={()=>{handleToHome()}} style={{color:"white",height:"1.7rem",width:"2rem"}}/>
                    <img className="main-header-img" alt="logo" src={Logo} />
                    </div>
                    <div className="search-box">
                        <button className="btn-search"><SearchIcon style={{ fontSize: "1.8rem",color:"black", fontWeight: 900 }} /></button>
                        <input type="text" className="input-search" placeholder="Type to Search..." />
                    </div>
                </div>

                <div className='right-section-header' style={{padding:0}}>
                    <div className="main-header-right">

                        <div className="bal-container">
                            <h5 className='rules'>Rules</h5>
                            <div className="balances">
                                <h5 style={{marginBottom:".3rem"}}><AccountBalanceIcon style={{height:".7em"}}/> : {parseFloat(userBalance.wallet_amount || 0).toFixed(2)}</h5>
                                <h4 style={{}}>Exp:{parseFloat(userBalance.exp_amount || 0).toFixed(2)}</h4>
                            </div>
                                {/* <DropdownId /> */}
                                 <div>
                                {token ? (<><DropdownId handleLogout={handleLogout} />
              {/* <div className="dropdown mt-0">

                <button className="dropbtn mt-2 pt-0 d-flex" >
                  <div className="person-icon-div">
                    <BsFillPersonFill className="person-icon" />
                  </div>
                  <IoMdArrowDropdown className="mt-2" />
                </button>
                <div className="dropdown-content">
                  <NavLink to="/profile">
                    <div className="d-flex justify-content-between">
                      PROFILE
                      <FaCreditCard />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/Deposit">
                    <div className="d-flex justify-content-between">
                      DEPOSIT
                      <FaCreditCard />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/DepositHistory">
                    <div className="d-flex justify-content-between">
                      DEPOSIT HISTORY
                      <FaCreditCard />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/WithdrawalUSDT">
                    <div className="d-flex justify-content-between">
                      WITHDRAWAL
                      <FaCreditCard />
                    </div>
                  </NavLink>
                  <NavLink to="/WithdrawalHistory">
                    <div className="d-flex justify-content-between">
                      WITHDRAWAL HISTORY <FaList />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/BettingHistory">
                    <div className="d-flex justify-content-between">
                      BETTING HISTORY
                      <MdLeaderboard />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/ProfitLoss">
                    <div className="d-flex justify-content-between">
                      PROFIT-LOSS
                      <MdOutlineLegendToggle />
                    </div>
                  </NavLink>
                  <NavLink to="/AccountStatement">
                    <div className="d-flex justify-content-between">
                      ACCOUNT STATEMENT <MdPayments />{" "}
                    </div>
                  </NavLink>
                  <NavLink to="/TransactionHistory">
                    <div className="d-flex justify-content- between">
                      TRANSACTION HISTORY
                      <IoMdTrendingUp />
                    </div>
                  </NavLink>
                  <NavLink to="/MyMarkeet">
                    <div className="d-flex justify-content-between ">
                      Unsellected Bet
                      <MdLeaderboard />
                    </div>
                  </NavLink>

                  <NavLink to="/FundsTransfer">
                    <div className="d-flex justify-content-between">
                      FUND TRANSFER
                      <IoMdSwap />
                    </div>
                  </NavLink>

                  {modal && <StakeSettingModal closeModal={closeModal} />}
                  <NavLink to="/CasinoResults">
                    <div className="d-flex justify-content-between">
                      CASINO RESULTS
                      <MdLeaderboard />
                    </div>
                  </NavLink>
                  <NavLink to="/ChangePassword">
                    <div className="d-flex justify-content-between">
                      CHANGE PASSWORD
                      <FaKey />
                    </div>
                  </NavLink>
                  <NavLink onClick={handleLogout} to="/">
                    <div className="d-flex justify-content-between">
                      LOGOUT
                      <FaKey />
                    </div>
                  </NavLink>
                </div>
              </div> */}
              

              </>) : ( 
               <div className="d-flex">
                {/* <NavLink to="/Login">
                  <button className="signin-btn mt-2  me-3">SignIn</button>
                </NavLink> */}
                {/* <NavLink to="/WalletSignIn">
                  <button className="wallet-btn mt-2  me-3">
                    Wallet Sign...
                  </button>
                </NavLink> */}
              </div>
            )} 
                        </div> 
                
                        </div>
                    </div>
                    <h1></h1>
                   
                </div>
            </div>
            <div className="marque-div">
                <Marquee  className="marque" >
                Check out our new advanced and updated version Greenlebel for best gaming experience with new multiple games and much more. Ask your agent for ID.
                    </Marquee>
                    </div>

</div>
            <div className="game-option popular-nav" style={{padding:0}} >
                <div className="nav-link"><NavLink to="/Home" className="navLink "> IPL 2024</NavLink></div>
                <div className="nav-link"><NavLink to="/Home" className="navLink w-auto">LOK SABHA 2024 </NavLink></div>
              </div>

{/**************      IN PLAY SECTION  *************/}
            <div className='InPlay-section'>
                <div className="nav-link"><NavLink to="/Home" className="navLink "> InPlay</NavLink></div>
                 <div className="nav-link"><NavLink to="/Home" className="navLink w-auto">Sports</NavLink></div>
                <div className="nav-link"><NavLink to="/LiveCasino" className="navLink ">Casino</NavLink></div>
                <div className="nav-link"><NavLink to="/LiveCasino" className="navLink w-auto">Others</NavLink></div>
              
            </div>

            {/* <PlayTabs /> */}

            {/* <div className="game-option"> */}
            <div className="header-bottom col-md-12">
              <nav className="navbar navbar-expand btco-hover-menu">
                <button
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                >
                  <span className="navbar-toggler-icon" />
                </button>{" "}
                <div className="collapse navbar-collapse  hxnOverflw">
                  <ul className="list-unstyled navbar-nav ">
                    <li className="nav-item active">
                      <Link
                        to="/Home"
                        aria-current="page"
                        className="router-link-exact-active router-link-active"
                      >
                        <b>Home</b>
                      </Link>
                    </li>{" "}
                    <li className="nav-item ">
                      <Link to="/Cricket" className="">
                        <b>Cricket</b>
                      </Link>
                    </li>{" "}
                     <li className="nav-item">
                      <a href="/game-detail/28127348" className="">
                        <b className="new-launch-text">IPL 2024</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/game-detail/1802162242" className="">
                        <b className="new-launch-text">PSL 2024</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/game-detail/1812151619" className="">
                        <b className="new-launch-text">Lok Sabha 2024</b>
                      </a>
                    </li>{" "} 
                    <li className="nav-item">
                      <a href="/game-list/tennis" className="">
                        <b>Tennis</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/game-list/football" className="">
                        <b>Football</b>
                      </a>
                    </li>{" "}
                    
                    <li className="nav-item">
                      <a href="/game-list/kabaddi" className="">
                        <b>Kabaddi</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/game-list/basketball" className="">
                        <b>Basketball</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/game-list/volleyball" className="">
                        <b>Volleyball</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/casino/baccaratlist" className="">
                        <span>
                          <b>Baccarat</b>
                        </span>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/casino/card32-list" className="">
                        <span>
                          <b>32Cards</b>
                        </span>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/casino/teenpatti-list" className="">
                        <span>
                          <b>Teenpatti</b>
                        </span>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/casino/poker-list" className="">
                        <span>
                          <b>Poker</b>
                        </span>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/game-list/table-tennis" className="">
                        <b>TableTennis</b>
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a href="/casino/lucky7-list" className="">
                        <span>
                          <b>Lucky7</b>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            {/* </div> */}
        </>
    );
};
