import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
const DropdownId = ({handleLogout}) => {
    // State to manage the anchor element for the menu
    const [anchorEl, setAnchorEl] = useState(null);
    // Function to handle menu opening
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // Function to handle menu closing
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return (
        <div style={{textDecoration:"underline"}}>
            <Button
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                endIcon={<ExpandMoreIcon style={{fontSize:"2rem",margin:0,fontWeight:600}}/>}
                style={{fontSize:"14px",fontFamily: "Roboto Condensed, sans-serif",color:"white",fontWeight:600,textDecoration:"underline",marginBottom:".5rem",marginTop:"-.5rem" }}
            >
                test502
            </Button>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                style={{marginLeft:0}}
            >
<MenuItem className="dropdown-nav">
                    <NavLink to="/" >
                        <div className="d-flex justify-content-between">
                            HOME 
                        </div>
                    </NavLink>
                </MenuItem>
<MenuItem className="dropdown-nav">
                    <NavLink to="/profile" >
                        <div className="d-flex justify-content-between">
                            PROFILE  
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/AccountStatement" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            ACCOUNT STATEMENT
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/ProfitLoss" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            PROFIT-LOSS Report
                        </div>
                    </NavLink>
                </MenuItem> 
                <MenuItem className="dropdown-nav">
                    <NavLink to="/BettingHistory" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            BETTING HISTORY
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/MyMarkeet" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            UNSELECTED BET 
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/CasinoResults" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            CASINO RESULTS
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/DepositHistory" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            DEPOSIT HISTORY 
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/WithdrawalUSDT" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            WITHDRAWAL
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/WithdrawalHistory" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            WITHDRAWAL HISTORY
        </div>
                    </NavLink >
                </MenuItem >
                <MenuItem className="dropdown-nav">
                    <NavLink to="/TransactionHistory" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            TRANSACTION HISTORY
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/FundsTransfer" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                          FUNDS TRANSFER
                        </div>
                    </NavLink>
                </MenuItem>
                <MenuItem className="dropdown-nav">
                    <NavLink to="/ChangePassword" onClick={handleMenuClose}>
                        <div className="d-flex justify-content-between">
                            CHANGE PASSWORD
                        </div>
                    </NavLink>
                </MenuItem>
                {/* <MenuItem onClick={handleMenuClose}>Account Statement</MenuItem>
                <MenuItem onClick={handleMenuClose}>Profit Loss Report</MenuItem>
                <MenuItem onClick={handleMenuClose}>Bet History</MenuItem>
                <MenuItem onClick={handleMenuClose}>Unsettled Bet</MenuItem>
                <MenuItem onClick={handleMenuClose}>Casino Report History</MenuItem>
                <MenuItem onClick={handleMenuClose}>Set Button Values</MenuItem>
                <MenuItem onClick={handleMenuClose}>Change Password</MenuItem> */}
                <hr style={{margin:0}}/>
                <MenuItem className="dropdown-nav" >
                    <NavLink to="/" onClick={handleLogout}>
                        <div className="d-flex justify-content-between" style={{color:"red"}}>
                            Sign Out
                        </div>
                    </NavLink>
                </MenuItem>
            </Menu>
        </div>
    );
};
export default DropdownId;