import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchbetStackData } from "../../../Redux/api/betStackAPI";
import { useParams } from "react-router-dom";
import { tab } from "@testing-library/user-event/dist/tab";

function BookTable({
  // tabName,
  tabData,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  // runnerName,
  setRealtimeData,
  setDataValue,
  setColrs,
}) {
  // console.log("book tab", tabData);

  const dispatch = useDispatch();
  const { id, mktId } = useParams();
  const userId = sessionStorage.getItem("userData");
  // console.log("id",userId)
  const token = sessionStorage.getItem("token");

  const [book1Stack, setbook1Stack] = useState("0");
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);

  const { bookStack, loading } = useSelector((state) => state.betStack);

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId,
        mId: id,
        matchType: "Bookmaker Market",
        token,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading) {
      if (tabData?.nat == bookStack[0]?.team) {
        setbook1Stack(bookStack[0]?.amount);
      } else if (tabData?.nat == bookStack[1]?.team) {
        setbook1Stack(bookStack[1]?.amount);
      } else if (tabData?.nat == bookStack[2]?.team) {
        setbook1Stack(bookStack[2]?.amount);
      }
    }
  }, [dispatch, loading]);

  const handleClick = (betDetails, bettype, marketType, value) => {
    handleModal(true);
    setRealtimeData(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    // setsubBetDetails(betDetails)
    setDataValue(value);
  };

  // console.log("oddStack", oddStack);

  return (
    <div className="row" style={{borderBottom:"1px solid white"}}>
      <div className="col-7 left-betting betting-t-n ">
        <p className="ms-2 mb-0 d-flex ">
          {tabData?.nat}
        </p>
        <p className={`ms-5  ${book1Stack >= 0 ? "success-1" : "zero"}`}>{book1Stack}</p>
      </div>
      <div className="col-5 d-flex pos_re">
        {(tabData.s === "SUSPENDED" || tabData.s === "Ball Running") && (
          <div className="tr_data_1">{tabData.s}</div>
        )}
        {tabData.b1 != 0.0 ? (
          <div className="blue-bet-btn mb-0">
            <div
              className="d-flex flex-column align-items-center"
              onClick={() =>{
                setColrs(1);
                handleClick(
                  {
                    marketId: mktId,
                    evntid: eventTypeid,
                    matchid: id,
                    selectionId: tabData.sid,
                    runnerName: tabData.nat,
                    handicap: 0,
                    status: tabData.s,
                    lastPriceTraded: 0,
                    totalMatched: 0,
                    Backprice1: tabData.b1,
                    Backprice: tabData.b1,
                    Backsize: tabData.bs1,
                    Layprice1: 0,
                    Layprice: 0,
                    Laysize: 0,
                    min: tabData.min,
                    max: tabData.max,
                    mname: "Bookmaker Market",
                    betRunnerName: tabData.nat,
                  },
                  "back",
                  "Bookmaker Market",
                  { valueOf: tabData?.b1, sid: tabData.sid, status: tabData?.s ,team:tabData.nat}
                )
              }}
            >
              {tabData?.b1}
              <span>
                {tabData?.bs1 < 1000
                  ? tabData?.bs1
                  : (tabData?.bs1 / 1000).toFixed(1) + "K"}
              </span>
            </div>
          </div>
        ) : (
          <div className=" blue-bet-btn ms-1 mb-0">
            <div className="d-flex flex-column align-items-center">
              0.0
              <span> 0.0</span>
            </div>
          </div>
        )}
        {tabData.l1 != 0.0 ? (
          <div className=" orange-bet-btn ms-1 mb-0">
            <div
              className="d-flex flex-column align-items-center"
              onClick={() =>{
                setColrs(2);
                handleClick(
                  {
                    marketId: mktId,
                    evntid: eventTypeid,
                    matchid: id,
                    selectionId: tabData.sid,
                    runnerName: tabData.nat,
                    handicap: 0,
                    status: tabData.s,
                    lastPriceTraded: 0,
                    totalMatched: 0,
                    Backprice1: 0,
                    Backprice: 0,
                    Backsize: 0,
                    Layprice1: tabData.l1,
                    Layprice: tabData.l1,
                    Laysize: tabData.ls1,
                    min: 0,
                    max: 0,
                    mname: "Bookmaker Market",
                    betRunnerName: tabData.nat,
                  },
                  "lay",
                  "Bookmaker Market",
                  { valueOf: tabData?.l1, sid: tabData.sid, status: tabData?.s,team:tabData.nat }
                )
              }}
            >
              {tabData?.l1}
              <span>
                {" "}
                {tabData?.ls1 < 1000
                  ? tabData?.ls1
                  : (tabData?.ls1 / 1000).toFixed(1) + "K"}
              </span>
            </div>
          </div>
        ) : (
          <div className=" orange-bet-btn ms-1 mb-0">
            <div className="d-flex flex-column align-items-center">
              0.0
              <span> 0.0</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(BookTable);
