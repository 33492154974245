import React, { memo } from "react";
import moment from "moment";
import "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
// import { updateCurrentMatch } from "../../../Redux/Slices/authSlice";
import { updateCurrentMatch } from "../../Redux/Slices/authSlice";
import { useDispatch } from "react-redux";
const MatchDataNew = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(updateCurrentMatch(data));
        navigate(`/Sport/${data?.MarketID}/${data?.id}`);
    };
    return (<>
        <div className="game-list">
            <div className="game-name" onClick={handleClick} >
                <p><span>{data?.name}</span> <span> {moment(data.openDate)
                    .tz("Asia/Kolkata")
                    .format("DD/MM/YYYY h:m A")}</span> </p>
                <div className="game-icons">
                    <span className="game-icon">
                        <span className="active" />
                    </span>{" "}
                    <span className="game-icon">
                        <i className="fas fa-tv v-m icon-tv" />
                    </span>{" "}
                    <span className="game-icon">
                        <img
                            src="https://dzm0kbaskt4pv.cloudfront.net/v17/static/front/img/icons/ic_fancy.png"
                            className="fancy-icon"
                        />
                    </span>{" "}
                    <span className="game-icon">
                        <img
                            src="https://dzm0kbaskt4pv.cloudfront.net/v17/static/front/img/icons/ic_bm.png"
                            className="bookmaker-icon"
                        />
                    </span>{" "}
                    {/* <span className="game-icon"></span> */}
                </div>
            </div>
            <div className="x12">
            <div className="row d-flex  ">
                    <p className="col-4 text-center m-0" style={{fontSize:"11px",fontWeight:"bold"}}>1</p> 
                    <p className="col-4 text-center m-0"style={{fontSize:"11px",fontWeight:"bold"}}>X</p>
                    <p className="col-4 text-center m-0"style={{fontSize:"11px",fontWeight:"bold"}}>2</p>
    </div>
    </div>
            <div className="result-list">
                <div>{data?.Back_1 || "-"}</div>
                <div>{data?.Lay_1 || "-"}</div>
                <div>{data?.Back_x || "-"}</div>
                <div>{data?.Lay_x || "-"}</div>
                <div>{data?.Back_2 || "-"}</div>
                <div>{data?.Lay_2 || "-"}</div>
            </div>
        </div>
        {/* <div
           className=" d-flex justify-content-between "
           onClick={() => handleClick()}>
        <tr>
            <td>
                <div className="game-name">
                    <Link to="/Cricket_Details" className="text-dark">
                        {data?.name} /  {moment(data.openDate)
                            .tz("Asia/Kolkata")
                            .format("DD/MM/YYYY h:m A")}
                        (IST)
                    </Link>
                </div>{" "}
                <div className="game-icons">
                    <span className="game-icon">
                        <span className="active" />
                    </span>{" "}
                    <span className="game-icon">
                        <i className="fas fa-tv v-m icon-tv" />
                    </span>{" "}
                    <span className="game-icon">
                        <img
                            src="https://dzm0kbaskt4pv.cloudfront.net/v17/static/front/img/icons/ic_fancy.png"
                            className="fancy-icon"
                        />
                    </span>{" "}
                    <span className="game-icon">
                        <img
                            src="https://dzm0kbaskt4pv.cloudfront.net/v17/static/front/img/icons/ic_bm.png"
                            className="bookmaker-icon"
                        />
                    </span>{" "}
                    <span className="game-icon"></span>
                </div>
            </td>{" "}
            <td>
                <button className="back">
                    <span className="odd">{data?.Back_1}</span>
                </button>
            </td>{" "}
            <td>
                <button className="lay">
                    <span className="odd">{data?.Lay_1}</span>
                </button>
            </td>{" "}
            <td>
                <button className="back">
                    <span className="odd">{data?.Back_x}</span>
                </button>
            </td>{" "}
            <td>
                <button className="lay">
                    <span className="odd">{data?.Lay_x}</span>
                </button>
            </td>{" "}
            <td>
                <button className="back">
                    <span className="odd">{data?.Back_2}</span>
                </button>
            </td>{" "}
            <td>
                <button className="lay">
                    <span className="odd">{data?.Lay_2}</span>
                </button>
            </td>
        </tr>
        </div> */}
    </>
    );
};
export default memo(MatchDataNew);
