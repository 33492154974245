import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./ColorGame.css";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaRegMinusSquare } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import io from "socket.io-client";
import ShowModal from "./ColorGameModal";
import { useSelector } from "react-redux";

const ColorGame = () => {
  // const { userId, jwt_token } = useSelector((state) => state.Auth);
  const userId = sessionStorage.getItem("userData");
  const jwt_token = sessionStorage.getItem("token");

  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState();
  const [roundresult, setRoundresult] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [Betdata, setBetData] = useState(null);
  const [roundDetails, setRoundDetails] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [remainingTime, setRemainingTime] = useState(0);
  const [loader, setloader] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  const numberButton = [
    {
      className: "violet-btn",
      value: 0,
      label: "0",
      color: "Violet",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 1,
      label: "1",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 2,
      label: "2",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 3,
      label: "3",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 4,
      label: "4",
      color: "Red",
      btn: false,
    },
    {
      className: "violet-btn",
      value: 5,
      label: "5",
      color: "Violet",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 6,
      label: "6",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 7,
      label: "7",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 8,
      label: "8",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 9,
      label: "9",
      color: "Green",
      btn: false,
    },
  ];

  const colorButton = [
    {
      className: "Green-button",
      color: "GREEN",
      label: "GREEN",
      value: 10,
      btn: true,
    },
    {
      className: "violet-button",
      color: "VIOLET",
      label: "VIOLET",
      value: 20,
      btn: true,
    },
    {
      className: "red-button",
      color: "RED",
      label: "RED",
      value: 30,
      btn: true,
    },
  ];

  const bigSmallButton = [
    {
      className: "btas",
      color: "Big",
      label: "Big",
      value: 40,
      btn: true,
    },
    {
      className: "btas",
      color: "Small",
      label: "Small",
      value: 50,
      btn: true,
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // const SOCKET_URL = "https://rohit-jain-socket-api.nakshtech.info/";
const SOCKET_URL = "";
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetInsertRound");

    cricketMatchesSocket.on("GetInsertRound_FromAPI", (data) => {
      // console.log("colorPlay", data[0]);

      if (data[0]) {
        setRoundDetails(data[0]);
      }

      const startRoundTime = new Date(data[0].startround).getTime();
      const endRoundTime = new Date(data[0].endround).getTime();
      const currentTime = new Date(data[0].currentDate).getTime();
      // console.log("Current Round ", currentTime);

      const timeDifferenceInSeconds = Math.floor(
        (endRoundTime - currentTime) / 1000
      );
      // console.log("diff", timeDifferenceInSeconds);

      const remainingCount = Math.max(0, timeDifferenceInSeconds);
      // console.log("remaining time", remainingTime);
      setRemainingTime(remainingCount);
      if (remainingCount === 0) {
        setloader(true);
      } else {
        setloader(false);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  function handleBetButton(btn) {
    if (remainingTime > 10 && roundDetails && roundDetails.id) {
      // console.log("btn", btn, roundDetails);
      let newData = { ...roundDetails, ...btn };
      // console.log("round", newData);
      setBetData(newData);
      setModal(true);
    }
  }

  const handleBetModalClose = () => {
    setModal(false);
    setBetData(null);
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("getColorbetHistory", userId);
    cricketMatchesSocket.on("getColorbetHistory_FromAPI", (data) => {
      console.log("History", data);
      setHistoryData(data);
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const ColorGameSocket = io(SOCKET_URL);
    ColorGameSocket.emit("GetColourResultSocket");
    ColorGameSocket.on("GetColourResultSocket_FromAPI", (data) => {
      console.log("roundresult-->", data);
      setRoundresult(data);
    });

    return () => {
      ColorGameSocket.disconnect();
    };
  }, []);

  return (
    <>
      {/* <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} /> */}
      {/* <Footer /> */}
      <div className="col-lg-10 ">
        <div className="row home ">
          <div className="col-lg-8 p-1">
            <div className="color-game-div pt-2 p-2">
              <div className="color-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">Color Game</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: #
                    {roundDetails && roundDetails.id ? roundDetails.id : "N/A"}{" "}
                    | Left Time: {remainingTime} | Rules
                  </p>
                </div>
              </div>

              <div className="card  color-game-card">
                {loader && (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div className="loading-text">Loading...</div>
                  </div>
                )}
                <div className="row mt-4">
                  {colorButton.map((btn, index) => (
                    <div className="col-4" key={index}>
                      <button
                        className={btn.className}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        readOnly
                      >
                        {btn.label}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="row mt-4">
                  <div className="col-md-7 mx-auto d-flex justify-content-between">
                    {numberButton.slice(0, 5).map((btn, index) => (
                      <div
                        className={`${btn.className} d-flex justify-content-center align-items-center`}
                        value={btn.value}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        key={index}
                        readOnly
                      >
                        {btn.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-7 mx-auto d-flex justify-content-between">
                    {numberButton.slice(5, 10).map((btn, index) => (
                      <div
                        className={`${btn.className} d-flex justify-content-center align-items-center`}
                        value={btn.value}
                        disabled={remainingTime > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        key={index}
                      >
                        {btn.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 mx-auto d-flex justify-content-between">
                  {" "}
                    {bigSmallButton.map((btn, index) => (
                      <div className="row">
                      <div className="col-lg-6" key={index}>
                        <button
                          className={btn.className}
                          disabled={remainingTime > 10 ? false : true}
                          onClick={() => handleBetButton(btn)}
                          readOnly
                        >
                          {btn.label}
                        </button>
                      </div>
                      </div>
                    ))}
                  </div>
                </div>                
              </div>

              {/* <-------------------------------Previous-ROund-Results-table----------> */}
              <div>
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Previous Rounds Results</p>
                  </div>
                </div>
                <div className="table-h-div row">
                  <div className="col-3 d-flex align-items-center ps-2">
                    Period
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Number
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Result
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    B/M
                  </div>
                </div>
                {roundresult
                  .filter((data) => data.status != 0)
                  .map((item) => (
                    <div className="table-inner-row row" key={item?.BetID}>
                      <div className="col-3 d-flex align-items-center ps-2">
                        {item?.BetID}
                      </div>
                      {item?.status !== 0 && (
                        <>
                          <div className="col-3 d-flex align-items-center justify-content-center px-2">
                            {item?.number}
                          </div>
                          <div className="col-3 d-flex align-items-center justify-content-center">
                            {item?.number != null && item?.number !== "" ? (
                              item?.number >= 0 && item?.number <= 9 ? (
                                item?.number == "0" || item?.number == "5" ? (
                                  <div className="violet-dot"></div>
                                ) : item?.number % 2 === 0 ? (
                                  <div className="red-dot"></div>
                                ) : (
                                  <div className="green-dot"></div>
                                )
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}

                      <div className="col-3 d-flex align-items-center ps-2">
                        {item?.NumberStatus}
                      </div>
                    </div>
                  ))}
              </div>

              {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>My Bets</p>
                  </div>
                </div>
                {historyData.map((item) => (
                  <>
                    <div className="table-inner-row row" key={item?.BetID}>
                      <div className="col-4 d-flex align-items-center ps-2">
                        {item?.BetID}
                      </div>
                      {item?.status == 0 && (
                        <div className="col-4 d-flex align-items-center fail">
                          Pending
                        </div>
                      )}

                      {item?.status != 0 && (
                        <>
                          <div
                            className={`col-2 d-flex align-items-center ${
                              item?.status == 1 ? "success" : "fail"
                            }`}
                          >
                            {item?.status == 1 ? "Win" : "Loss"}
                          </div>

                          <div
                            className={`col-2 d-flex align-items-center ${
                              item?.resultStatus == 0 ? "fail" : "success"
                            }`}
                          >
                            {item?.stake < 100
                              ? parseFloat(item?.stake).toFixed(2)
                              : item?.stake < 1000
                              ? parseFloat(item?.stake).toFixed(1)
                              : `${(parseFloat(item?.stake) / 1000).toFixed(
                                  2
                                )}K`}
                          </div>
                        </>
                      )}

                      <div className="col-4 d-flex align-items-center justify-content-end ">
                        <div
                          onClick={() => {
                            toggle === item?.BetID
                              ? setToggle(null)
                              : setToggle(item?.BetID);
                          }}
                        >
                          {toggle === item?.BetID ? (
                            <FaRegMinusSquare className="plus-icon" />
                          ) : (
                            <FaRegPlusSquare className="plus-icon" />
                          )}
                        </div>
                      </div>
                    </div>
                    {toggle === item?.BetID && (
                      <div className="card card2 bet-menu-card">
                        <div className="row px-2 pt-2 bet-menu-card-h text-white">
                          Period Detail
                        </div>
                        <div className="row">
                          <div className="col-6 px-3 pt-1">Period</div>
                          <div className="col-6  pt-1">{item?.roundid}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Contract Money</div>
                          <div className="col-6 pt-1">{item?.stake}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Delivery</div>
                          <div className="col-6 pt-1">{item?.odds}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Fee</div>
                          <div className="col-6 pt-1">{item?.Charge}</div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Result</div>
                          <div className="col-6 pt-1 d-flex ">
                            {item?.status === 1
                              ? "Win"
                              : item?.status === 2
                              ? "Loss"
                              : ""}

                            {item?.resultColor === "Green" ? (
                              <div className="green-dot ms-2"></div>
                            ) : item?.resultColor === "Red" ? (
                              <div className="red-dot ms-2"></div>
                            ) : item?.resultColor === "Violet" ? (
                              <>
                                {" "}
                                <div className="violet-dot ms-2"></div>
                                {/* <div className="red-dot ms-2"></div> */}
                              </>
                            ) : item?.resultColor === "Violet" ? (
                              <>
                                {" "}
                                <div className="violet-dot ms-2"></div>
                                {/* <div className="green-dot ms-2"></div> */}
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Select</div>
                          {item.ColorBet}
                          {/* <div
                            className={`col-6 pt-1 ${
                              item?.resultStatus == 0 ? "fail" : "success"
                            }`}
                          >
                            {" "}
                            {item?.ball !== null &&
                            item?.ball !== undefined &&
                            Number(item?.ball) == 12
                              ? item?.color
                              : item?.ball}
                          </div> */}
                        </div>
                        {/* <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Status</div>
                          <div
                            className={`col-6 pt-1  ${
                              item?.resultStatus == 0 ? "fail" : "success"
                            }`}
                          >
                            {" "}
                            {item?.status == 0
                              ? "Pending"
                              : item?.status == 1 && item?.resultStatus == 0
                              ? "Lost"
                              : item?.resultStatus == 1 && item?.status == 1
                              ? "Win"
                              : "Pending"}
                          </div>
                        </div> */}
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Amount</div>
                          <div
                            className={`col-6 pt-1  ${
                              item?.resultStatus == 0 ? "fail" : "success"
                            }`}
                          >
                            {" "}
                            {item?.stake}
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="col-6 px-3 pt-1">Create Time</div>
                          <div className="col-6 pt-1 ">{item?.dd}</div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        
          <RightSidebar />
        
          {/* <RightSidebar /> */}
       
        </div>
      </div>

          {modal && (
            <ShowModal
              open={setModal}
              data={Betdata}
              onClose={handleBetModalClose}
            />
          )}
    </>
  );
};

export default ColorGame;
