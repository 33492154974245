import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MatchesDetail from "../MatchesDetail/MatchesDetail";
import RightSidebar from "../RightSidebar/RIghtSidebar";
// import HeroMain from "../HeroMain/HeroMain";
// import FootBall from "../FootBall/FootBall";
// import Tennis from "../Tennis/Tennis";
// import Cricket from "../Cricket/Cricket";
// import Esoccer from "../Esoccer/Esoccer";
// import HorseRacing from "../HorseRacing/HorseRacing";
// import TableTennis from "../TableTennis/TableTennis";
// import #cccHound from "../#cccHound/#cccHound";
// import BasketBall from "../BasketBall/BasketBall";
// import VolleyBall from "../VolleyBall/VolleyBall";
// import Kabaddi from "../Kabaddi/Kabaddi";
// import Badminton from "../Badminton/Badminton";
// import USFootball from "../USFootball/USFootball";
// import Futsal from "../Futsal/Futsal";
// import EGames from "../EGames/EGames";
// import Snooker from "../Snooker/Snooker";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function PlayTabs() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (<>
    <Box sx={{ width: "100%" }} style={{ width: "100%", padding: 0}}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ textTransform: "capitalize",overflowX:"scroll" }}
        // style={{overflowX:"scroll"}}
        >
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black",
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsCricketIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Cricket"
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsSoccerIcon style={{ color: "white", "@media(min-width: 750px)":{display:"none"} }} />}
            label="Football"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              padding: "14px 4px",
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsTennisIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Tennis"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsBaseballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Esoccer"
            {...a11yProps(3)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",//Yellow
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "5px 1rem",
              margin: "0 auto"
            }}
            icon={<SportsTennisIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Horse Racing"
            {...a11yProps(4)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsTennisIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Table Tennis"
            {...a11yProps(5)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsTennisIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="#ccchound Racing"
            {...a11yProps(6)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsBasketballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Basketball"
            {...a11yProps(7)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsVolleyballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Volleyball"
            {...a11yProps(8)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsKabaddiIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Kabaddi"
            {...a11yProps(9)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsTennisIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Badminton"
            {...a11yProps(10)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
              minWidth: "auto",
              backgroundColor: "black", // Change background color for smaller screens
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
              },
              color: "white",
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsFootballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="American Football"
            {...a11yProps(11)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto"
            }}
            icon={<SportsFootballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Futsal"
            {...a11yProps(12)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto",
            }}
            icon={<SportsFootballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="E Games"
            {...a11yProps(13)}
          />
          <Tab
            sx={{
              fontSize: "10px",
              maxWidth: "auto",
               minWidth: "15px",
              backgroundColor: "black", // Change background color for smaller screens
                color: "white",
              "@media (max-width: 750px)": {
                backgroundColor: "#ffc629",
                minWidth: "12px",
              color: "black",
              },
              minHeight: "2px",
              borderRight: "1px solid white",
              textTransform: "capitalize",
              fontWeight: "800",
              padding: "0 1rem",
              margin: "0 auto",
            }}
            icon={<SportsFootballIcon style={{ color: "white", "@media (min-width: 750px)":{display:"none"} }} />}
            label="Snooker"
            {...a11yProps(14)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={0}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={1}>
        <MatchesDetail activeIndex={1} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={2}>
        <MatchesDetail activeIndex={2} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={3}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={4}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={5}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={6}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={7}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={8}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={9}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={10}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={11}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={12}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={13}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
      <CustomTabPanel style={{ paddingLeft: ".5rem", paddingRight: "1rem" }} value={value} index={14}>
        <MatchesDetail activeIndex={4} />
        {/* <RightSidebar /> */}
      </CustomTabPanel>
    </Box>
  </>);
}
