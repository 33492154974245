import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://battlemoney-match-api.nakshtech.info/";

const API = axios.create({
  baseURL,
});

const initialState = {
  userBalance: {},
  isLoading: false,
  error: null,
};

export const fetchuserBalance = createAsyncThunk(
  "user/balance",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      let result = await API.post(
        "/getwallet_exposure",
        {
          uid: id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("userbalnce", result.data.data[0][0]);
      return result.data.data[0][0];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userBalanceSlice = createSlice({
  name: "userbalance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchuserBalance.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchuserBalance.rejected, (state, action) => {
        state.isLoading = true;
        state.error = action.payload;
      })

      .addCase(fetchuserBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.userBalance = action.payload;
      });
  },
});

export default userBalanceSlice.reducer;
