import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import SessionTable from "./Table1/SessionTable";
import { fetchSessionStackData } from "../../Redux/api/sessionStackAPI";
import { useDispatch } from "react-redux";
const Session = ({
  setbetDetails,
  setmarketType,
  setbetType,
  handleModal,
  setcountryName,
  setrealtimeData,
  marketType,
  realtimeData,
  betType,
  setColrs,
}) => {
  const { id } = useParams();
  const SOCKET_URL = "https://greenlebel-socket-api.nakshtech.info/";
  const [data, setData] = useState([]);
  const [dataValue, setDataValue] = useState("");
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("sessionid", id);
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketAllMarket1", id);
    cricketMatchesSocket.on("CricketAllMarket1_FromAPI", (data) => {
      // console.log("Session_data", data);
      if (data && data.length > 0) {
        setData(data);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);
  const checkValid = () => {
    // console.log("valid", dataValue);
    if (realtimeData && marketType === "Session Market") {
      const filteredData = data?.filter(
        (item) =>
          item.RunnerName == dataValue?.team &&
          item.SelectionId == dataValue?.sid
      );
      // console.log("filter", filteredData);
      if (betType === "back") {
        if (
          filteredData[0].SelectionId == dataValue?.sid &&
          filteredData[0].GameStatus != "SUSPENDED" &&
          filteredData[0]?.BackPrice1 == dataValue?.valueOf &&
          filteredData[0]?.RunnerName == dataValue?.team
        ) {
          return;
        } else {
          // console.log("valid2", bm[1].b1);
          setrealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].SelectionId == dataValue?.sid &&
          filteredData[0].GameStatus != "SUSPENDED" &&
          filteredData[0]?.LayPrice1 == dataValue?.valueOf &&
          filteredData[0]?.RunnerName == dataValue?.team
        ) {
          return;
        } else {
          setrealtimeData(false);
          setDataValue({});
        }
      }
    }
  };
  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, data]);
  useEffect(() => {
    dispatch(
      fetchSessionStackData({
        userId: uid,
        id: id,
        mname: "Session Market",
        token,
      })
    );
  }, []);
  return (
    <>
      {data && data.length > 0 ? (
        <div className="row bgContainer " style={{ paddingLeft: "0",marginLeft: ".8rem"}}>
        <div className="Match-ods-h d-flex align-items-center">
          <p className="mx-2 mt-3 d-flex">
                Session MARKET <div className="green-live-dot ms-2 mt-2"></div>
              </p>
            </div>
            <div className="row back-lay-row  ">
          <div className="col-7 betting-t-n "></div>
          <div className="col-5 d-flex " style={{paddingLeft:"1.5rem",paddingRight: 0}}>
        <div className="blue-bet-btn mb-0" style={{height:"2.1rem"}}>
          <div className="d-flex flex-column align-items-center">
           Back
          </div>
        </div>
        <div className=" orange-bet-btn ms-1 mb-0" style={{height:"2.1rem"}}>
          <div className="d-flex flex-column align-items-center">
            Lay
          </div>
        </div>
      </div>
          {/* <div className="col-5 ">
            <button className="back ">Back</button>
            <button className="lay ms-1">Lay</button>
          </div> */}
        </div>
        <div className="betting-team-info">
            {data.map((item, index) => {
              return (
                <SessionTable
                  key={index}
                  tabData={item}
                  handleModal={handleModal}
                  setbetType={setbetType}
                  setmarketType={setmarketType}
                  setbetDetails={setbetDetails}
                  // sessionName="Session Market"
                  setcountryName={setcountryName}
                  setrealtimeData={setrealtimeData}
                  setDataValue={setDataValue}
                  setColrs={setColrs}
                />
              );
            })}
            </div>
          </div>
      ) : null}
    </>
  );
};
export default memo(Session);
