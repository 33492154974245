import React, { useEffect, useState } from "react";
import { MdScreenshotMonitor } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

const LiveTvSection = () => {
  const { id } = useParams();

  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashbetHistory, isLoading, error } = useSelector(
    (state) => state.dashbetHistory
  );
  const [tableData, setTableData] = useState([]);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [active, setActive] = useState(true);
  const [zoom, setZoom] = useState(false);
  const zoomClick = () => {
    setZoom(!zoom);
  }
  const zoomOutClick=()=>{
    setZoom(!zoom);
  }
  // useEffect(() => {
  //   const fetchDashData = async () => {
  //     try {
  //       const response = fetchDashbetHistory({ userId, token });
  //       console.log("DashBetHistory", response);
  //       setTableData(response);
  //     } catch (error) {
  //       console.error("Error fetching DAshbet history:", error);
  //     }
  //   };

  //   fetchDashData();
  // }, [dispatch, userId, token]);

  // useEffect(() => {
  //   dispatch(
  //     fetchDashbetHistory({
  //       data: {
  //         uid: userId,
  //         fromDate: "",
  //         toDate: "",
  //       },
  //       token,
  //     })
  //   );
  // }, [dispatch]);

    useEffect(() => {
      // Calculate the width dynamically
      const width = Math.min(window.innerHeight * 1.65, window.innerWidth); // Assuming this is what you intended with Math.min(165vh || 100vw)
      console.log("Ifram Width : ", width);
      console.log("I fram height : ",window.innerHeight);
      const ml= window.innerWidth-width;
      const marginL= Math.max((ml/2) ,0);
      const marginTop=window.innerHeight - width/1.65

        // Apply the dynamically calculated width
        const iframeDiv = document.querySelector('.ifram-div-full');
        const zoombtn = document.querySelector('.zoom-btn');
        if (iframeDiv) {
            iframeDiv.style.width = `${width}px`;
            iframeDiv.style.marginLeft =`${marginL}px`;
          }
        if(zoom ){
          zoombtn.style.marginTop = `-${marginTop+80}px`
        }
    }, [zoom]);
  return (
    <div className="mb-2 d-lg-block col-12 d-flex ">
      <div className={active ? "live-tv-card" : "live-tv-card-active"}>
        <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
          <div className="d-flex mt-1 ms-1 ">
            <MdScreenshotMonitor className="tv-icon" />
            <p className="ms-2 mb-2">LIVE TV</p>
          </div>

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              value={active}
              onChange={() => setActive(!active)}
            />
          </div>
        </div>
        {active && (zoom === false) && (
          <div className="ifram-div" >
            <iframe
              allowFullScreen={true}
              style={{
                overflow: "hidden",
                width: "110%",
                height: "90%",
                opacity: "1",
                visibility: "visible",
                color: "white ",
                padding: "-1rem",
                border: "1px solid black",
              }}
              id="frame_video_2"
              // src={`https://stream.1ex99.in/tv2?EventId=${id}`}
              src={`https://battlemoney.live/AViframe/${id}`}
            ></iframe>
            <div onClick={zoomClick} className="zoom-btn-full"><ZoomOutMapIcon style={{ color: "#c5c4c4", zIndex: 99, fontSize: "1.5rem" }} /></div>
          </div>
        )}
      </div>

      {zoom && (
        <div className="fixed-ifram-div">
        <div className="ifram-div-full" >
          <iframe
            allowFullScreen={true}
            style={{
              overflow: "hidden",
              width: "106%",
              height: "100%",
              opacity: "1",
              visibility: "visible",
              color: "white ",
              padding: "-1rem",
              border: "1px solid black",
            }}
            id="frame_video_2"
            // src={`https://stream.1ex99.in/tv2?EventId=${id}`}
            src={`https://battlemoney.live/AViframe/${id}`}
          ></iframe>
          <div onClick={zoomOutClick} className="zoom-btn" ><ZoomOutMapIcon style={{ color: "#c5c4c4", zIndex: 99, fontSize: "1.5rem" }} /></div>
        </div>
        </div>

      )}
      <div className="matched-bets mt-3">
          <div
            className="d-flex justify-content-between tv-card mb-0 pb-0 p-1"
            style={{ overflow: "scroll" ,border:"1px solid black"}}
          >
            <div className="" style={{ height: "12.4rem",width:"99%" }}>
              <div className="mybet_table">
                <div className="table-responsive">
                  <table className="tablee">
                    <thead style={{backgroundColor:"black"}}>
                      <tr className="text-white">
                        <th className="text-white">Matched Bet</th>

                        <th className="text-white">Odds</th>
                        <th className="text-white">Stake</th>
                        <th className="text-white">Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashbetHistory?.map((item) => {
                        // console.log("item", item.team.length);
                        return item.back > 0 ? (
                          <tr
                            key={item.id}
                            // style={{ backgroundColor: "#0b9cf1" }}
                          >
                            <td className="td_bet_items">{item.team}</td>
                            <td>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td>{item.stake}</td>
                            <td>{item.odds || 0}</td>
                          </tr>
                        ) : (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#f37a13" }}
                          >
                            <td className="td_bet_items">{item.team}</td>

                            <td>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td>{item.stake}</td>
                            <td>{item.odds || 0}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bet-matches ms-2 p-2">AUSTRALIA VS NEWZEELAND</div> */}
          {/* <div className="no-bets ms-1 p-2">NO BETS</div> */}
        </div>
    </div>
  );
};

export default LiveTvSection;
