import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://greenlebel-api.nakshtech.info/",
});

export const casinoBet = createAsyncThunk(
  "bet/placeBet",
  async ({ betDetails, token }) => {
    console.log("betslice", betDetails);
    try {
      const response = await API.post(
        "/addProCasinoBet",
        {
          ...betDetails,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("casinobet Res", response);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const casinobetSlice = createSlice({
  name: "casinobet",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(casinoBet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(casinoBet.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(casinoBet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default casinobetSlice.reducer;
