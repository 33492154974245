import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRoutes() {
  const { isAuth } = useSelector((state) => state.auth);
  console.log("PrivateRoutes", isAuth);

  return <>{isAuth === true ? <Outlet /> : <Navigate to="/" />}</>;
}
