import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import OddsTable from "./Table1/OddsTable";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import CashOut from "./CashOut";
const MatchOdds = ({
  counteyName,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  realtimeData,
  betType,
  marketType,
  setColrs,
}) => {
  const { mktId, id } = useParams();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("userData");
  // console.log("id",userId)
  const token = sessionStorage.getItem("token");
  // console.log("realtiem",realtimeData)
  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";
  const [t1, setT1] = useState([]);
  const [bm, setBm] = useState([]);
  const [t2, setT2] = useState([]);
  const [t3, setT3] = useState([]);
  const [t4, setT4] = useState([]);
  const [showMatchOdds, setshowMatchOdds] = useState(false);
  const [drawt1, setdrawT1] = useState([]);
  const [drawt2, setdrawT2] = useState([]);
  const [sus, setSus] = useState("0.0");
  const [sus1, setSus1] = useState("0.0");
  const [sus2, setSus2] = useState("0.0");
  const [dataValue, setDataValue] = useState("");
  const { lastbet } = useSelector((state) => state.lastbetDetails);
  useEffect(() => {
    // console.log("Odd socket before",mktId)
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatchType", mktId);
    cricketMatchesSocket.on("CricketOddMatchType_FromAPI", (data) => {
      // console.log("MatchOdds==>", data);
      if (data && data.length > 0 && data[0].runners) {
        let res = data;
        setshowMatchOdds(true);
        setT1(res[0].runners[0]?.ex?.availableToBack || []);
        setT2(res[0].runners[0]?.ex?.availableToLay || []);
        setT3(res[0].runners[1]?.ex?.availableToBack || []);
        setT4(res[0].runners[1]?.ex?.availableToLay || []);
        setdrawT1(res[0].runners[2]?.ex?.availableToBack || []);
        setdrawT2(res[0].runners[2]?.ex?.availableToLay || []);
        setSus(res[0].runners[0] || {});
        setSus1(res[0].runners[1] || {});
        setSus2(res[0].runners[2] || {});
      } else {
        setshowMatchOdds(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [mktId]);
  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId,
        mId: id,
        matchType: "Match Odds",
        token,
      })
    );
  }, [dispatch, id]);
  const checkValid = () => {
    // console.log("valid", realtimeData);
    if (
      realtimeData &&
      marketType === "Match Odds" &&
      (sus?.selectionId !== dataValue?.selId ||
        sus1?.selectionId !== dataValue?.selId ||
        sus2?.selectionId !== dataValue?.selId)
    ) {
      console.log("valid1", dataValue, realtimeData);
      if (betType === "back") {
        if (
          t1[0]?.price == dataValue?.valueOf ||
          t3[0]?.price == dataValue?.valueOf ||
          drawt1[0]?.price == dataValue?.valueOf
        ) {
          return;
        } else {
          console.log("valid2", t1[0]?.price);
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          t2[0]?.price !== dataValue?.valueOf ||
          t4[0]?.price !== dataValue?.valueOf ||
          drawt2[0]?.price !== dataValue?.valueOf
        ) {
          return;
        } else {
          console.log("valid3", t2[0]?.price);
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };
  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, t1]);
  const [showCashout, setShowCashOut] = useState(false);
  const [odd1Stack, setOdd1Stack] = useState(0);
  const [odd2Stack, setOdd2Stack] = useState(0);
  const [odd3Stack, setOdd3Stack] = useState(0);
  const calculateLiability = () => {
    let liability =
      odd1Stack > 0 && odd2Stack > 0
        ? "0"
        : Math.abs(Math.min(odd1Stack, odd2Stack));
    return liability;
  };
  const calculatePoints = () => {
    if (counteyName && counteyName.length > 0) {
      if (lastbet[0]?.runnerName === counteyName[0]?.runnerName) {
        return lastbet[0]?.Bet === "Back" ? t2[0]?.price : t1[0]?.price;
      } else if (lastbet[0]?.runnerName === counteyName[1]?.runnerName) {
        return lastbet[0]?.Bet === "Back" ? t4[0]?.price : t3[0]?.price;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  const calculateProfit = () => {
    if (counteyName && counteyName.length > 0) {
      if (lastbet[0]?.runnerName === counteyName[0]?.runnerName) {
        return Math.abs(odd1Stack);
      } else if (lastbet[0].runnerName === counteyName[1]?.runnerName) {
        return Math.abs(odd2Stack);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  const calculatedifference = (points) => {
    let difference = Math.abs(points - lastbet[0]?.Bhav);
    difference =
      lastbet[0]?.Bet === "Back" && points > lastbet[0]?.Bhav
        ? difference * -1
        : (difference =
            lastbet[0]?.Bet === "Lay" && lastbet[0]?.Bhav > points
              ? difference * -1
              : difference);
    return difference;
  };
  const calculateCashOut = (diff, lia) => {
    // console.log("123",diff,lia,lastbet[0].Stakebet)
    if (lia == 0) {
      return Math.max(odd1Stack, odd2Stack);
    } else {
      return diff * lastbet[0].Stakebet;
    }
  };
  const calculateDivide = (cashout) => {
    // let ModifiyCashout = cashout > 0 ? cashout / 2 : cashout;
    let ModifiyCashout = cashout;
    return ModifiyCashout;
  };
  function calcuteTenPercentage(divide) {
    const TenPercent =
      divide < 0
        ? divide - Math.abs(divide * 0.1)
        : divide - Math.abs(divide * 0.1);
    return TenPercent;
  }
  const Cashout = useCallback(() => {
    const pointss = calculatePoints();
    // console.log("points", pointss);
    const difference = calculatedifference(pointss);
    // const profit = calculateProfit();
    const Liability = calculateLiability();
    const cashOut =
      (odd1Stack > 0 && odd2Stack > 0) || (odd1Stack < 0 && odd2Stack < 0)
        ? (odd1Stack + odd2Stack) / 2
        : calculateCashOut(difference, Liability);
    // console.log("Last", cashOut);
    // ADD Commission
    const divide = calculateDivide(cashOut);
    const AddTenPercentage = calcuteTenPercentage(divide);
    const finalcashout =
      AddTenPercentage < 0 && Math.abs(AddTenPercentage) > Liability
        ? -1 * Liability
        : AddTenPercentage;
    // console.log("final", finalcashout);
    return finalcashout;
  }, [odd1Stack, odd2Stack, lastbet, t1, t2, t3, t4]);
  useEffect(() => {
    if (lastbet.length > 0) {
      setShowCashOut(true);
    }
  }, [lastbet]);
  // useEffect(() => {
  //   if (odd1Stack == 0 || odd2Stack == 0 ) {
  //     console.log("setShowCashout",odd1Stack,odd2Stack)
  //     setShowCashOut(false);
  //   }
  // }, [odd1Stack, odd2Stack]);
  return (
    <div style={{zIndex:99}}>
      {showCashout &&
        odd1Stack !== 0 &&
        odd2Stack !== 0 &&
        t1[0]?.price > 1.02 &&
        t1[1]?.price > 1.02 &&
        t2[0]?.price > 1.02 &&
        t2[1]?.price > 1.02 &&
        showMatchOdds &&
        counteyName &&
        counteyName?.length > 0 &&
        !counteyName[2]?.runnerName && (
          <div className="liab_bars">
            <CashOut
              calculateLiability={calculateLiability}
              Cashout={Cashout}
              setShowCashOut={setShowCashOut}
            />
          </div>
        )}
      <div className="row bgContainer ">
        <div className="Match-ods-h d-flex align-items-center">
          <p className="mx-2 mt-3 d-flex">
            MATCH ODDS <div className="green-live-dot ms-2 mt-2"></div>
          </p>
        </div>
        <div className="row back-lay-row  ">
          <div className="col-7 betting-t-n "></div>
          <div className="col-5 d-flex " style={{paddingLeft:"1.5rem",paddingRight: 0}}>
        <div className="blue-bet-btn mb-0" style={{height:"2.1rem"}}>
          <div className="d-flex flex-column align-items-center">
           Back
          </div>
        </div>
        <div className=" orange-bet-btn ms-1 mb-0" style={{height:"2.1rem"}}>
          <div className="d-flex flex-column align-items-center">
            Lay
          </div>
        </div>
      </div>
          {/* <div className="col-5 ">
            <button className="back ">Back</button>
            <button className="lay ms-1">Lay</button>
          </div> */}
        </div>
        <div className="betting-team-info">
          {counteyName && counteyName.length > 0 && showMatchOdds && (
            <>
              <OddsTable
                tabName={counteyName[0]}
                runnerName={counteyName[1].runnerName}
                back={t1}
                lay={t2}
                sus={sus}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                realtimeData={realtimeData}
                setDataValue={setDataValue}
                stakefunction={setOdd1Stack}
                setColrs={setColrs}

              />
              <OddsTable
                tabName={counteyName[1]}
                runnerName={counteyName[0].runnerName}
                back={t3}
                lay={t4}
                sus={sus1}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                realtimeData={realtimeData}
                setDataValue={setDataValue}
                stakefunction={setOdd2Stack}
                setColrs={setColrs}
              />
              {counteyName.length > 2 && (
                <>
                  <OddsTable
                    tabName={counteyName[2]}
                    runnerName=""
                    back={drawt1}
                    lay={drawt2}
                    sus={sus2}
                    handleModal={handleModal}
                    setmarketType={setmarketType}
                    setbetType={setbetType}
                    setbetDetails={setbetDetails}
                    setRealtimeData={setRealtimeData}
                    setDataValue={setDataValue}
                    stakefunction={setOdd3Stack}
                    setColrs={setColrs}
                  />
                </>
              )}
            </>
          )}
          {!showMatchOdds && <div className="text-center py-2">No Bet !</div>}
        </div>
      </div>
    </div>
  );
};
export default MatchOdds;
