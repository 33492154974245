import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import BookTab from "./Table1/BookTab";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useDispatch } from "react-redux";
const BookMaker = ({
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  marketType,
  betType,
  realtimeData,
  setColrs,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [bm, setBm] = useState([]);
  const [dataValue, setDataValue] = useState("");
  const SOCKET_URL = "https://greenlebel-socket-api.nakshtech.info/";
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketBookMarket2", id);
    cricketMatchesSocket.on("CricketBookMarket2_FromAPI", (data) => {
      // console.log("Bookmaker_data", data);
      setBm(data);
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);
  function stop() {
    document.getElementById("marquee1").stop();
  }
  function start() {
    document.getElementById("marquee1").start();
  }
  const checkValid = () => {
    // console.log("valid", realtimeData);
    if (realtimeData && marketType === "Bookmaker Market") {
      const filteredData = bm?.filter(
        (item) => item.nat == dataValue?.team && item.sid == dataValue?.sid
      );
      // console.log("valid1", dataValue, filteredData);
      if (betType === "back") {
        if (
          filteredData[0].s != "SUSPENDED" &&
          filteredData[0].b1 == dataValue?.valueOf &&
          dataValue?.valueOf > 0
        ) {
          console.log(
            "valid1.2",
            filteredData[0].s != "SUSPENDED" &&
              filteredData[0].b1 == dataValue?.valueOf
          );
          return;
        } else {
          console.log("valid2", filteredData[0].b1);
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].s != "SUSPENDED" &&
          filteredData[0].l1 == dataValue?.valueOf &&
          dataValue?.valueOf > 0
        ) {
          return;
        } else {
          console.log("valid3", filteredData[0].l1);
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };
  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, bm]);
  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId: uid,
        mId: id,
        matchType: "Bookmaker Market",
        token,
      })
    );
  }, [dispatch, id]);
  return (
    <>
      {bm && bm.length > 0 ? (
        <>
          <marquee
            id="marquee1"
            direction="left"
            scrollamount="4"
            onMouseOver={stop}
            onMouseOut={start}
            className="text-danger"
          >
            {bm[0]?.remark}
          </marquee>
          <div className="row  bgContainer "style={{ paddingLeft: "0",marginLeft: ".8rem",marginBottom:".5rem"}}>
            <div className="Match-ods-h d-flex align-items-center">
              <p className="mx-2 mt-3 d-flex">
                BOOKMAKER FAST BET(0 % COMMISION){" "}
                <div className="green-live-dot ms-2 mt-2"></div>
              </p>
            </div>
            <div className="row back-lay-row ">
              <div className="col-7 betting-t-n"></div>
              <div className="col-5 d-flex " style={{paddingLeft:"1.5rem",paddingRight: 0}}>
        <div className="blue-bet-btn mb-0" style={{height:"2.1rem"}}>
          <div className="d-flex flex-column align-items-center">
           Back
          </div>
        </div>
        <div className=" orange-bet-btn ms-1 mb-0" style={{height:"2.1rem"}}>
          <div className="d-flex flex-column align-items-center">
            Lay
          </div>
        </div>
      </div>
            </div>
            <div className="betting-team-info">
              {bm?.map((item, index) => (
                <BookTab
                  key={index}
                  // tabName={counteyName[0]}
                  // runnerName={counteyName[1]?.runnerName}
                  tabData={item}
                  handleModal={handleModal}
                  setmarketType={setmarketType}
                  setbetType={setbetType}
                  setbetDetails={setbetDetails}
                  setRealtimeData={setRealtimeData}
                  setDataValue={setDataValue}
                  setColrs={setColrs}
                />
              ))}
              {/* <BookTab
                tabName={counteyName[1]}
                runnerName={counteyName[0]?.runnerName}
                tabData={bm[1]}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
              /> */}
            </div>
          </div>
          <marquee
            id="marquee1"
            direction="left"
            scrollamount="4"
            onMouseOver={stop}
            onMouseOut={start}
            className="text-danger"
          >
             {bm[1]?.remark}
          </marquee>
        </>
      ) : null}
    </>
  );
};
export default memo(BookMaker);
