import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://greenlebel-api.nakshtech.info/",
});

export const fetchDashbetHistory = createAsyncThunk(
  "user/fetchDashbetHistory",
  async ({ userId, token }) => {

    try {
      const response = await API.post(
        "/BetHistoryDashboard",
        {
          uid: userId,
          fromDate: "",
          toDate: "",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("DashbetHistory Res", response);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

const dashbetHistorySlice = createSlice({
  name: "dashbetHistory",
  initialState: {
    dashbetHistory: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashbetHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashbetHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.dashbetHistory = action.payload;
      })
      .addCase(fetchDashbetHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error ? action.error.message : "Unknown error";
      });
  },
});

export default dashbetHistorySlice.reducer;
