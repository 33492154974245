import React, { useEffect, useState } from "react";
import "../Deposit/Deposit.css";
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchBetaWalletData,
//   selectBetaWalletInfo,
// } from "../../Redux/Slices/betaWalletSlice";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";

const Withdrawal = () => {
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  let uId = sessionStorage.getItem("userName");
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // const info = useSelector(selectBetaWalletInfo);
  // console.log("info", info);
  // useEffect(() => {
  //   dispatch(fetchBetaWalletData({ id: "USERID780362", token }));
  // }, []);

  const [old, setData] = useState("0.00");
  const [password, setPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [valueData, setValueData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [select, setSelect] = useState("USDT");

  const connectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        setInputValue(accounts[0]);
      } else {
        console.log("MetaMask not found");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    connectToMetaMask();
  }, []);

  const hendelclick = (event) => {
    let value = event.target.value;
    let sanitizedValue = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] });
    sanitizedValue = sanitizedValue.replace(/[^0-9]/g, "");
    setAmount(sanitizedValue);
    ConverAmount(sanitizedValue);
  };

  const ConverAmount = async (value) => {
    try {
      let res = await API.get(
        `WithdrawlConversationAmount?PtcAmount=${value}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      res = res.data.data[0];
      console.log("ConverAmount", res);
      setValueData(res);
    } catch (e) {
      console.log("Something Error in ConvertAmount API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const Live_Rate = async () => {
    try {
      let res = await API.post(
        `/getbetawallet`,
        { username: uId },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("old", res);
      res = res.data.data[0][0].totalnetbal;
      setData(res);
    } catch (e) {
      console.log("Error While Fatch  Live_RateAPI", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Live_Rate();
  }, []);

  const Withdrwal_API = async () => {
    try {
      if (inputValue == "No Wallet") {
        toast.error("No Wallet");
        // setconnected("Wallet is locked");
      } else if (inputValue == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        // setconnected("Wrong Network");
      } else if (inputValue == "") {
        toast.error("Please Connect Wallet First !!");
      } else {
        let res = await API.post(
          "UserWithdrawl",
          {
            uid: userId,
            amount: amount,
            type: select,
            password: password,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("Withdrwal", res.data);
        // setscoreboard_data(res.data)
        if (res.data.data === "SUCCESSFUL") {
          toast.success(res.data.data);
          // Live_Rate();
          window.location.reload();
        } else {
          toast.error(res.data.data);
        }
      }
    } catch (error) {
      console.log("Something Error in Withdrwal API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  return (
    <div className="col-lg-10 ms-2 dropdown-content-Withdraw" >
      <div className="row">
        <div className="col-12 px-2 ">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pt-0 pb-0">
              <p className="ms-3">Withdrawal USDT</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className=" card deposit-card mg-lg-4 mt-2 py-2 col-lg-8">
                <div className="px-4">
                  {/* <form> */}
                  <div className="row mt-2">
                    <div className="col-md-6 px-1">
                      <div className="form-group">
                        <label for="exampleInputEmail1">
                          Available Balance
                        </label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          placeholder="Available Balance"
                          value={old}
                          readOnly
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-md-6 px-1">
                      <div className="form-group">
                        <label for="exampleInputEmail1">Select Token</label>
                        <select
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          onChange={(e) => setSelect(e.target.value)}>
                          <option>SelectToken</option>
                          <option value="AGCToken">AGC Token</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-md-6 px-1">
                      <div className="form-group">
                        <label for="exampleInputEmail1">Enter PTS Amount</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          placeholder="Enter Amount"
                          value={amount}
                          onChange={(e) => hendelclick(e)}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 px-1">
                      <div className="form-group">
                        <label for="exampleInputEmail1">USD Amount</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          placeholder="Calculated USD Amount"
                          value={valueData.USDAmount}
                          disabled
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-md-6 px-1">
                      <div className="form-group">
                        <label for="exampleInputEmail1">AGC Amount</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          placeholder="Calculated AGC Amount"
                          value={valueData.AGCAmount}
                          disabled
                          maxLength={10}
                        />
                      </div>
                    </div>

{/* 
                      <div className="form-group">
                        <label for="exampleInputEmail1">
                          Transaction Password
                        </label>
                        <input
                          className="form-control mt-2"
                          id="exampleInputEmail1"
                          type="text"
                          placeholder="Transaction Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    */}                   
                  </div>

                  <button
                    // type="submit"
                    className="btn deposit-f-btn mt-lg-4 mt-2 "
                    onClick={() => Withdrwal_API()}>
                    Withdrawal Balance
                  </button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrawal;
