import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "react-collapse";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { fetchMatchName } from "../../Redux/Slices/matchNameSlice";
import { useNavigate } from "react-router-dom";
import { updateCurrentMatch } from "../../Redux/Slices/authSlice";

export const CollapseDatas=({activeIndex,toggle,isOpen})=>{
    const [data,setData] = useState();
    const dispatch = useDispatch();
    const token = sessionStorage.getItem("token");
    const { matchName, loading, error } =
      useSelector((state) => state.matchInfo) || [];
    useEffect(() => {
      dispatch(fetchMatchName({ id: activeIndex, token })).unwrap();
    }, [dispatch, activeIndex]);




    const navigate = useNavigate();
    console.log("ajjfisofjjajfojfdio")
    const handleClick = () => {
      dispatch(updateCurrentMatch(data));
      navigate(`/Sport/${data?.MarketID}/${data?.id}`);
    };
    return(<>
        {/* <div style={{ marginLeft:"1rem"}} onClick={toggle}>{isOpen ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Cricket</div> */}

          {matchName?.map((item, index) => { console.log("Match Detailsxxxxxxxxxxxxxxxxxxxxx :  ",activeIndex);
            return new Date(item.openDate) >= new Date() ? (
                <Collapse isOpened={isOpen}  >
              {/* {setData(item)} */}
             <div style={{ marginLeft:"2rem",fontSize:".8rem" ,cursor:"pointer"}}> <ArrowRightIcon /> {item.name}</div>
            </Collapse>
            ) : (
                <Collapse isOpened={isOpen}  >
             <div style={{ marginLeft:"2rem",fontSize:".8rem",cursor:"pointer"}}> <ArrowRightIcon /> {item.name}</div>
            </Collapse>
            );
              {/* <InPlayMatchData key={index} data={item} /> */}
          })}
            
          
    </>);
}