import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Sidebar.css";
import { Collapse } from "react-collapse";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { CollapseDatas } from "./collapseData";

const Sidebar1 = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);
  const [isOpen9, setIsOpen9] = useState(false);
  const [isOpen10, setIsOpen10] = useState(false);
  // const [isOpen11, setIsOpen11] = useState(false);
  // const [isOpen12, setIsOpen12] = useState(false);

  const toggle1 = () => setIsOpen1(!isOpen1);
  const toggle2 = () => setIsOpen2(!isOpen2);
  const toggle3 = () => setIsOpen3(!isOpen3);
  const toggle4 = () => setIsOpen4(!isOpen4);
  const toggle5 = () => setIsOpen5(!isOpen5);
  const toggle6 = () => setIsOpen6(!isOpen6);
  const toggle7 = () => setIsOpen7(!isOpen7);
  const toggle8 = () => setIsOpen8(!isOpen8);
  const toggle9 = () => setIsOpen9(!isOpen9);
  const toggle10 = () => setIsOpen10(!isOpen10);
  // const toggle11 = () => setIsOpen11(!isOpen11);
  // const toggle12 = () => setIsOpen12(!isOpen12);

  
  return (
    <div style={{minWidth:"18%"}}>
      <div className="sidebar hxnSiderBar">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="custom-header" >Others</Accordion.Header>
            <Accordion.Body>
              <ul className="list-unstyled navbar-nav">
                <li className="nav-item">
                  <a href="/tembo-list" className="nav-link">
                    <span className="new-launch-text">Tembo Casino</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/livecasino" className="nav-link">
                    <span>Live Casino</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/slotgame" className="nav-link">
                    <span>Slot Game</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/race/t20" className="nav-link">
                    <span>Race 20-20</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/queen" className="nav-link">
                    <span>Casino Queen</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/dt-list" className="nav-link">
                    <span>Dragon Tiger</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/sportscasinolist" className="nav-link">
                    <span>Sports Casino</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/ablist" className="nav-link">
                    <span>Andar Bahar</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/bollywood-table" className="nav-link">
                    <span>Bollywood Casino</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/war" className="nav-link">
                    <span>Casino War</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/worlilist" className="nav-link">
                    <span>Worli</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/lottery" className="nav-link">
                    <span>Lottery</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/casino/3cardj" className="nav-link">
                    <span>3 Cards Judgement</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/binary-list" className="nav-link">
                    <span>Binary</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/virtualsports" className="nav-link">
                    <span>Virtual Sports</span>
                  </a>
                </li>{" "}
                <li className="nav-item">
                  <a href="/cricket-casino" className="nav-link">
                    <span>Cricket Casino</span>
                  </a>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>


          <Accordion.Item eventKey="1">
            <Accordion.Header>All Sports</Accordion.Header>
            <Accordion.Body>
              <div style={{ marginLeft:"1rem"}} onClick={toggle1}>{isOpen1 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Cricket</div>
                <CollapseDatas activeIndex={4} toggle={toggle1} isOpen={isOpen1}  />
              <div style={{ marginLeft:"1rem"}} onClick={toggle2}>{isOpen2 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} FootBall</div>
                <CollapseDatas activeIndex={2}  isOpen={isOpen2}  />
              <div style={{ marginLeft:"1rem"}} onClick={toggle3}> {isOpen3 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Esoccer</div>
                <Collapse isOpened={isOpen3} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> Esoccer</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle4}> {isOpen4 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Tennis</div>
                <Collapse isOpened={isOpen4} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> Tennis</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle5}> {isOpen5 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Ice Hockey</div>
                <Collapse isOpened={isOpen5} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> Ice Hockey</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle6}> {isOpen6 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} VolleyBall</div>
                <Collapse isOpened={isOpen6} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> VolleyBall</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle7}> {isOpen7 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Table Tennis</div>
                <Collapse isOpened={isOpen7} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> Table Tannis</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle8}> {isOpen8 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} BasketBall</div>
                <Collapse isOpened={isOpen8} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> BasketBall</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle9}> {isOpen9 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Badminton</div>
                <Collapse isOpened={isOpen9} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> Badminton</div>
                </Collapse>
              <div style={{ marginLeft:"1rem"}} onClick={toggle10}> {isOpen10 ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />} Kabaddi</div>
                <Collapse isOpened={isOpen10} >
                 <div style={{ marginLeft:"2.3rem"}}> <ArrowRightIcon /> Kabaddi</div>
                </Collapse>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Sidebar1;
