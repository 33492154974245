import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLossProfitData } from "../../Redux/Slices/lossProfitSlice";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import moment from "moment";
import { fetchLossProfit } from "../../Redux/api/lossProfitAPI";
import { useNavigate } from "react-router-dom";

const ProfitLoss = () => {
  const dispatch = useDispatch();
  const { lossProfitData, isLoading, error } = useSelector(
    (state) => state.lossProfit
  );
  const username = sessionStorage.getItem("userName");
  // const username = useSelector(state => state.auth.user.data.resultusername);

  // console.log("resultusername:", username);




  
  const invalidToken = useSelector((state) => state.lossProfit.invalidToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (invalidToken) {
      sessionStorage.removeItem("userData");
      sessionStorage.removeItem("token");
      navigate("/");
    }
  }, [invalidToken]);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");
  const [select, setSelect] = useState("all");

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const uid = sessionStorage.getItem("userData");
    
    const fetchData = async () => {
      try {
        const response = await fetchLossProfit(
          uid,
          fromdate,
          todate,
          token,
          username
        );
        setTableData(response);
      } catch (error) {
        console.error("Error fetching profit/loss data:", error);
      }
    };
    fetchData();
  }, [fromdate, todate]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = tableData.slice(indexOfFirstPage, indexOfLastPost);
  const filteredData =
    select === "all"
      ? currentPost
      : currentPost.filter((item) => item.matchstatus == select);

  // console.log("filteredData", filteredData);

  const handleSearch = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const userId = sessionStorage.getItem("userData");
      const response = await fetchLossProfit(userId, fromdate, todate, token, username);
      setTableData(response);
    } catch (error) {
      console.error("Error fetching profit/loss data:", error);
    }
  };

  return (
    <div className="col-lg-10 ms-3 com-h dropdown-content-profit" >
      <div className="row">
        <div className="col-12 ">
          <div className="bet-h ">
            <h4 className="p-2">Profit & Loss</h4>
            <div className="dates-div p-2 d-flex align-items-center ">
              <div className="row d-flex align-items-center">
                <div className="col-md-4 mt-1">
                  <div className="d-flex">
                    <div className="from d-flex align-items-center justify-content-center">
                      From:
                    </div>
                    <div className="">
                      <input
                        className="from-control"
                        type="date"
                        value={fromdate}
                        onChange={(e) => setfromdate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-1">
                  <div className="d-flex ">
                    <div className="from d-flex align-items-center justify-content-center">
                      To:
                    </div>
                    <div className="">
                      <input
                        className="from-control"
                        type="date"
                        value={todate}
                        onChange={(e) => settodate(e.target.value)}
                      />
                    </div>
                   
                  </div>
                </div>
                {/* <div className="col-md-5 mt-1">
                  <div className="d-flex ">
                    <div className="from d-flex align-items-center justify-content-center">
                      To:
                    </div> */}
                    <div className="col-md-2 ">
                      <select
                        className="form-control"
                        onChange={(e) => setSelect(e.target.value)}>
                        <option value="all">All</option>
                        <option value="1">Profit</option>
                        <option value="0">Loss</option>
                      </select>
                    </div>
                   
                  {/* </div>
                </div> */}
                <div className="col-md-2 mt-1">
                  <button
                    className="btn btn-success "
                    onClick={() => handleSearch()}>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>{" "} */}
                    Search
                  </button>
                </div>
              </div>
            </div>

            <div className="mybet_table">
              <div className="table-responsive">
                <table className="tablee">
                  <thead className="Statement_head">
                    <tr>
                      <th>No</th>
                      <th>User</th>
                      <th>Date & Time</th>
                      <th>Event</th>
                      <th>Event Type</th>
                      <th>Match</th>
                      <th>Yes</th>
                      <th>No</th>
                      <th>Bhav</th>
                      <th>Profit/Loss</th>
                      <th>Stack Amount</th>
                      <th>Win Amount</th>
                      {/* <th>Profit Amount</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * listPerpage + index + 1}</td>
                        <td>{item.username}</td>
                        <td>
                          {moment(item.edate).format("DD/MM/YYYY h:m:s A")}
                        </td>
                        <td>{item.remark}</td>
                        <td>{item.team}</td>
                        <td>{item.name}</td>
                        <td>{item.back}</td>
                        <td>{item.lay}</td>
                        <td>
                          {item.BackSize1 > 0 ? item.BackSize1 : item.LaySize1}
                        </td>

                        <td>
                          {item.matchstatus == "0" ? (
                            <span className="badge badge-danger">Loss</span>
                          ) : (
                            <span className="badge badge-success">Profit</span>
                          )}
                        </td>

                        <td>{item.amount}</td>
                        <td>{item.winamount}</td>
                        {/* <td>{item.profitamount}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Table_Buttons
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
              </div>
            </div>
            {/* <Table data={[...currentPost]} columns={matching_income.cols} /> */}
            {/* <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={referralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitLoss;
