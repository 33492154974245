import React, { useState, useEffect } from "react";
import SliderH from "./SliderH";
import { FaPlay } from "react-icons/fa";
import "./MatchesDetail.css";
import cricket from "../../Media/5.png";
import { BsFillPersonFill } from "react-icons/bs";
import tennis from "../../Media/4.png";
import football from "../../Media/6.png";
import MatchData from "../NewMatchDelatils/MatchData";
// import InPlayMatchData from "../NewMatchDelatils/InPlayMatchDataNew";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import aircraft from "../../Media/output-onlinepngtools (5).png";
import { NavLink } from "react-router-dom";
// import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchMatchName } from "../../Redux/Slices/matchNameSlice";
import InPlayMatchData from "../NewMatchDelatils/InPlayMatchDataNew";
// import InPlayMatchData from "./InPlayMatchData";
// import { fetchData } from "../../Redux/Slices/apiSlices";
const MatchesDetail = ({activeIndex}) => {
  // const [activeIndex, setActive] = useState(4);
  const [isVisible, setIsVisible] = useState(true);
  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const { matchName, loading, error } =
    useSelector((state) => state.matchInfo) || [];
  useEffect(() => {
    dispatch(fetchMatchName({ id: activeIndex, token })).unwrap();
  }, [dispatch, activeIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="col col-12 p-1 center-div">
      {/* <SliderH /> */}
      <div className="MainArea ">
        <div className="sport-div1  row body-section">
          {/* <div className="sports-horiz-row d-flex">
            <div
              onClick={() => {
                setActive(4);
              }}
              className={`single-sport-div ${activeIndex === 4 && "is-active"}`}
            >
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 4 && "is-activ"
                    }`}
                  >
                    <img
                      className=" sport-img ms-1"
                      src={cricket}
                      alt="cricket"
                    />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 4 && "is-act"
                    }`}
                  >
                    CRICKET
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setActive(1);
              }}
              className={`single-sport-div ${activeIndex === 1 && "is-active"}`}
            >
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 1 && "is-activ"
                    }`}
                  >
                    <img className=" sport-img " src={football} alt="cricket" />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 1 && "is-act"
                    }`}
                  >
                    SOCCER
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setActive(2);
              }}
              className={`single-sport-div ${activeIndex === 2 && "is-active"}`}
            >
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-center align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 2 && "is-activ"
                    }`}
                  >
                    <img
                      className=" sport-img ms-1"
                      src={tennis}
                      alt="cricket"
                    />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 2 && "is-act"
                    }`}
                  >
                    TENNIS
                  </p>
                </div>
              </div>
            </div>
            <div className="single-sport-div ms-4">
              <NavLink to="/ColorGame" className="nav-link">
                <div className="blink-img-div">
                  {isVisible && (
                    <img
                      className="image-size img-fluid "
                      src={colorimg}
                      alt="a"
                    />
                  )}
                </div>
                <p className="ms-1  sport-name">COLOR</p>
              </NavLink>
            </div>
            <div className="single-sport-div ms-1">
              <NavLink to="/Aviator" className="nav-link">
                <div className="image-div">
                  {isVisible && (
                    <img
                      className=" a-image-size img-fluid mt-2"
                      src={aircraft}
                      alt="a"
                    />
                  )}
                </div>
                <p className="ms-1 mt-1 sport-name mt-3">AVIATOR</p>
              </NavLink>
            </div>
          </div> */}
          {/* <----------------------MatchData-----------------------> */}
          <div className="tab-content">
        <div className="tab-pane active">
          <div className="coupon-card coupon-card-first">
            <div className="card-content">
              <table className="table coupon-table">
                <thead>
                  <tr>
                    <th style={{ width: "63%",paddingLeft:"1rem"}}><div style={{display:"flex",fontWeight:600}}>Game</div></th>{" "}
                    <th colSpan={2}>1</th> 
                    <th colSpan={2}>X</th>{" "}
                    <th colSpan={2}>2</th>
                  </tr>
                </thead>{" "}
                </table>
                </div>
                </div>
                </div>
                </div>
          {matchName?.map((item, index) => {
             {/* console.log("Match Details :  ",item); */}
            return new Date(item.openDate) >= new Date() ? (
              <MatchData key={index} data={item} />
            ) : (
              <InPlayMatchData key={index} data={item} />
            );
          })}
          {/* <InPlayMatchData /> */}
          {/* <MatchData />
          <MatchData />
          <MatchData /> */}
        </div>
      </div>
    </div>
  );
};
export default MatchesDetail;
