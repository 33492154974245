import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import OddsTable from "./Table1/OddsTable";
const CompletedMatch = ({ counteyName, ComplmarkId }) => {
  const { mktId } = useParams();
  const SOCKET_URL = "https://greenlebel-socket-api.nakshtech.info/";
  const [t5, setT5] = useState([]);
  const [t6, setT6] = useState([]);
  const [t7, setT7] = useState([]);
  const [t8, setT8] = useState([]);
  const [showComp, setShowComp] = useState(false);
  useEffect(() => {
    console.log("compl",ComplmarkId )
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatchType", ComplmarkId);
    cricketMatchesSocket.on("CricketOddMatchType_FromAPI", (data) => {
      // console.log("comp",data)
      let res = data;
      if (res != null && res.length > 0) {
        setShowComp(true);
        setT5(res[0].runners[0].ex.availableToBack);
        setT6(res[0].runners[0].ex.availableToLay);
        setT7(res[0].runners[1].ex.availableToBack);
        setT8(res[0].runners[1].ex.availableToLay);
      } else {
        setShowComp(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [ComplmarkId]);
  return (
    <>
      {showComp ? (
        <div>
          <div className="row  bg-dark ">
            <div className="Match-ods-h d-flex align-items-center">
              <p className="mx-2 mt-3 d-flex">
                COMPLETED MATCH<div className="green-live-dot ms-2 mt-2"></div>
              </p>
            </div>
            <div className="row back-lay-row  bg-dark ">
              <div className="col-7 "></div>
              <div className="col-5 ">
                <button className="back ">Back</button>
                <button className="lay ms-1">Lay</button>
              </div>
            </div>
            <div className="betting-team-info">
              {counteyName && counteyName.length > 0 && (
                <>
                  <OddsTable tabName={counteyName[0]} back={t5} lay={t6} />
                  <OddsTable tabName={counteyName[1]} back={t7} lay={t8} />
                  {/* <OddsTable tabName={counteyName[2]} back={drawt1} lay={drawt2} /> */}
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default memo(CompletedMatch);
