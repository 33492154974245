import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import OddsTable from "./Table1/OddsTable";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useDispatch } from "react-redux";
import TiedTable from "./Table1/TiedTable";
const TiedMatch = ({
  counteyName,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  realtimeData,
  TiedmarkId,
  setColrs,
}) => {
  const { mktId } = useParams();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("userData");
  // console.log("id",userId)
  const token = sessionStorage.getItem("token");
  const SOCKET_URL = "https://greenlebel-socket-api.nakshtech.info/";
  const [t9, setT9] = useState([]);
  const [t10, setT10] = useState([]);
  const [t11, setT11] = useState([]);
  const [t12, setT12] = useState([]);
  const [sus, setSus] = useState({});
  const [sus1, setSus1] = useState({});
  const [dataValue, setDataValue] = useState("");
  const [showTied, setShowTied] = useState(false);
  useEffect(() => {
    // console.log("Odd socket before",mktId)
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatchType", TiedmarkId);
    cricketMatchesSocket.on("CricketOddMatchType_FromAPI", (data) => {
      // console.log("Tied Match==>", data);
      if (data && data.length > 0 && data[0].runners) {
        let res = data;
        setShowTied(true);
        setT9(res[0].runners[0].ex.availableToBack);
        setT10(res[0].runners[0].ex.availableToLay);
        setT11(res[0].runners[1].ex.availableToBack);
        setT12(res[0].runners[1].ex.availableToLay);
        setSus(res[0].runners[0] || {});
        setSus1(res[0].runners[1] || {});
      } else {
        setShowTied(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [TiedmarkId]);
  // useEffect(() => {
  //   dispatch(
  //     fetchbetStackData({
  //       userId,
  //       mId: mktId,
  //       matchType: "Match Odds",
  //       token,
  //     })
  //   );
  // }, [dispatch, TiedmarkId]);
  return (
    <>
      {counteyName && counteyName.length > 0 && showTied ? (
        <>
          <div>
            <div className="row  bg-dark ">
              <div className="Match-ods-h d-flex align-items-center">
                <p className="mx-2 mt-3 d-flex">
                  Tied Match <div className="green-live-dot ms-2 mt-2"></div>
                </p>
              </div>
              <div className="row back-lay-row  bg-dark ">
                <div className="col-7 "></div>
                <div className="col-5 ">
                  <button className="back ">Back</button>
                  <button className="lay ms-1">Lay</button>
                </div>
              </div>
              <div className="betting-team-info">
                <>
                  <TiedTable
                    tabName={counteyName[0]}
                    runnerName={counteyName[1].runnerName}
                    back={t9}
                    lay={t10}
                    sus={sus}
                    handleModal={handleModal}
                    setbetType={setbetType}
                    setmarketType={setmarketType}
                    setbetDetails={setbetDetails}
                    setRealtimeData={setRealtimeData}
                    setColrs={setColrs}
                  />
                  <TiedTable
                    tabName={counteyName[1]}
                    runnerName={counteyName[0].runnerName}
                    back={t11}
                    lay={t12}
                    sus={sus1}
                    handleModal={handleModal}
                    setmarketType={setmarketType}
                    setbetType={setbetType}
                    setbetDetails={setbetDetails}
                    setRealtimeData={setRealtimeData}
                    setColrs={setColrs}
                  />
                </>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
export default memo(TiedMatch);
