import React, { useEffect, useState } from "react";
import a from "../../Media/img1.png";
import e from "../../Media/img5.png";
import b from "../../Media/img2.png";
import c from "../../Media/32CardsB.webp";
import d from "../../Media/img4.png";
import f from "../../Media/img6.png";
import g from "../../Media/img7.png";
import { NavLink } from "react-router-dom";
import { API } from "../../API/API";
import axios from "axios";
const SmallCards = () => {

  const [games, setGames] = useState([]);

  
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get("https://battlemoney-auth-api.nakshtech.info/MasterGame?type=1");
        // console.log("Response data:", response.data.data[0]);
        setGames(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching games", error);
      }
    };

    fetchGames();
  }, []);
 
  return (
    <>
    <div className="image-lists-container">
     {games?.map((list) => (
      <NavLink to={`/TeenPatti/${list.id}`} className="image-list text-decoration-none"  key={list.id}>
      {/* <NavLink to={`/TeenPatti/${list.id}`} className=""> */}

                    <div className="img-cont">
                        <img  src={list.images} alt={list.name} />
                    </div>
                {/* </NavLink> */}
                    <div >{list.name}</div>
                </NavLink>
                ))}
                </div>

      {/* <div className="col-2"   key={list.id}>
        <NavLink to={`/TeenPatti/${list.id}`} className="nav-link">
          <div className="card smallcard-div  mt-3">
            <img className=" small-card-img" 
            // src={a} 
            src={list.images}
            alt={list.name} />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">{list.name}</p>
            </div>
          </div>
        </NavLink>
        <div className="name-image" style={{backgroundColor:"#2c3e50"}}>BALL BY BALL</div>
      </div> */}
       
      {/* <div className="col-6">
        <NavLink to="/32Cards" className="nav-link">
          <div className="card smallcard-div ms-2 mt-3">
            <img className=" small-card-img" src={b} alt="a" />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">32 CardA</p>
            </div>
          </div>
        </NavLink>
      </div>
      <div className="col-6">
        <NavLink className="nav-link" to="/32CardsB">
          <div className="card smallcard-div ms-2 mt-3">
            <img className=" small-card-img" src={c} alt="a" />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">32 CardB</p>
            </div>
          </div>
        </NavLink>
      </div>
      <div className="col-6">
        <NavLink to="/DragonTiger" className="nav-link">
          <div className="card smallcard-div ms-2 mt-3">
            <img className=" small-card-img" src={d} alt="a" />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">Dragon Tiger 20-20</p>
            </div>
          </div>
        </NavLink>
      </div>
      <div className="col-6">
        <NavLink className="nav-link" to="/Lucky7A">
          <div className="card smallcard-div ms-2 mt-3">
            <img className=" small-card-img" src={e} alt="a" />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">Lucky 7A</p>
            </div>
          </div>
        </NavLink>
      </div> */}
      {/* <div className="col-6">
        <NavLink to="/ColorGame" className="nav-link">
          <div className="card smallcard-div ms-2 mt-3">
            <img className=" small-card-img" src={f} alt="a" />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">Color Game</p>
            </div>
          </div>
        </NavLink>
      </div>
      <div className="col-12 p-2">
        <NavLink to="/Aviator" className="nav-link">
          <div className="card aviator-card mt-2 ">
            <img className=" aviator-card-img" src={g} alt="a" />
            <div className="overlayAviatorCard d-flex justify-content-center">
              <p className="overlayAviatorcard-p">Aviator</p>
            </div>
          </div>
        </NavLink>
      </div> */}
    </>
  );
};

export default SmallCards;
