import React from 'react'
import { useParams } from 'react-router-dom';

export default function AviatorIframe() {
    const { Url } = useParams();
    // console.log("url-->", Url);

    const iframeUrl =  `https://www.youtube.com/embed/tgbNymZ7vqY`;
    const iframeUrl1=`https://stream.1ex99.in/tv2?EventId=${Url}`;

  return (
    <>
    <div className="" style={{ height: "100vh" }}>
      <iframe
        height="100%"
        width="100%"
        src={iframeUrl1}
        title=""></iframe>
    </div>
  </>
  )
}
