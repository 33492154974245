// import { Collapse } from "bootstrap";
import React, { useEffect, useState } from "react";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";
import batting from "../../Media/batting.png";
import bowling from "../../Media/bowling.png";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import moment from "moment";
const ScoreCard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [score, setScore] = useState([]);
  const [ShowScore, setShowScore] = useState(false);
  const { ComName, openDate } = useSelector((state) => state.auth.currentMatch);
  const SOCKET_URL = "https://greenlebel-socket-api.nakshtech.info/";
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetIframe", id);
    cricketMatchesSocket.on("GetIframe_FromAPI", (data) => {
      // console.log("RamRam==>", data);
      let res = data;
      if (res.message === "Success.") {
        setShowScore(true);
        setScore(res.data);
      } else {
        setShowScore(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);
  return (
    <>
     {ShowScore &&<div className="conatiner-left-div1 my-1" style={{marginLeft:".15rem"}}>
                    <div className="left-container-heading">
                        <h5 style={{marginLeft:"1rem"}}>{score[2]?.team1} VS {score[2]?.team2}</h5>
                        <h5></h5>
                    </div>
                    <div className="left-container-total-run">
                        <div className="left-container-total-run-left">
                        <div>
                                <h5>{score[2]?.team1}</h5>
                            <div className="run-rate">
                                <h5>{score[2]?.score}-{score[2]?.wicket} ({(
                          Math.floor(score[2]?.ballsdone / 6) +
                          (score[2]?.ballsdone % 6) / 10
                        ).toFixed(1)})</h5>
                            </div>
                            </div>
                        <div>
                            <h5>{score[2]?.team2}</h5>
                        <div className="run-rate">
                                <h5>{score[2]?.score2}-{score[2]?.wicket2} {" "}({(
                          Math.floor(score[2]?.ballsdone2 / 6) +
                          (score[2]?.ballsdone2 % 6) / 10
                        ).toFixed(1)})</h5>
                            </div>
                            </div>
                        </div >
                        <div className="left-container-total-run-balls">
                        {/* {score && score[0]?.cb && (
                  <p> {score[0]?.cb}</p>
              )} */}
                        {Array.isArray(score[0]?.recentBalls) &&
                        score[0]?.recentBalls[0]
                          .map((digit) => digit.trim())
                          .filter(Boolean)
                          .map((digit, index) => (
                            <div
                              className={`${
                                digit === "w" || digit === "wd"
                                  ? "red-info"
                                  : digit === "0"
                                  ? "no-run-info"
                                  : digit === "1"
                                  ? "single-info"
                                  : digit == "4"
                                  ? "six-info"
                                  : digit == "6"
                                  ? "six-info"
                                  : "single-info"
                              }`}
                              key={index}
                            >
                              {digit}
                            </div>
                          ))}
                        </div>
                    </div>
                </div>
     }
      {/* <div className="px-1 d-lg-none  d- block">
        <div className="TV-outer-border px-3 py-2">
          <div className="live-tv1">
            <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
              <iframe
                allowFullScreen={true}
                style={{
                  width: "100%",
                  height: "fit-content",
                  border: "0",
                  opacity: "1",
                  visibility: "visible",
                  color: "white ",
                }}
                id="frame_video_2"
                // src={`https://stream.1ex99.in/tv2?EventId=${id}`}
                src={`https://battlemoney.live/AViframe/${id}`}
              ></iframe>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default ScoreCard;
