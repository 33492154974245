import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://greenlebel-api.nakshtech.info/",
});

export const rulesData = createAsyncThunk(
  "casino/rules",
  async (_, { rejectWithValue }) => {
    try {
      const response = await API.get(`Rules?type=2 `);
      return response.data.data[0];
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const rulesSlice = createSlice({
  name: "rules",
  initialState: {
    error: null,
    rules: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(rulesData.fulfilled, (state, action) => {
        state.error = null;
        state.rules = action.payload;
      })
      .addCase(rulesData.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Unknown error";
      });
  },
});

export default rulesSlice.reducer;
