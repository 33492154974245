import React, { memo, useEffect, useState } from "react";
import MatchOdds from "./MatchOdds";
import BookMaker from "./BookMaker";
import Fancy from "./Fancy";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import Completed from "./Completed";
import Session from "./Session";
import { useDispatch } from "react-redux";
import { placeBet } from "../../Redux/Slices/betSlice";
import { toast } from "react-toastify";
import SportModal from "./SportModal";
import TiedMatch from "./TiedMatch";
import { saveTeams } from "../../Redux/Slices/SaveTeamsSlice";
const OpenMarkeet = () => {
  const { id } = useParams();
  const SOCKET_URL = "https://greenlebel-socket-api.nakshtech.info/";
  const [counteyName, setcountrydata] = useState([]);
  const [Tiedlabel, setTiedlabel] = useState([]);
  const [Complabel, setComplabel] = useState([]);
  const [ComplmarkId, setComplmarkId] = useState("");
  const [TiedmarkId, setTiedmarkId] = useState("");
  const [compType, setcompType] = useState("");
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [socketData, setSocketData] = useState([]);
  const [realtimeData, setRealtimeData] = useState(false);
  const [count, setCount] = useState(0);
  const [CompCount, setCompCount] = useState(0);
  const [TiedCount, setTiedCount] = useState(0);
  useEffect(() => {
    // console.log("Label Before",id)
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatch", id);
    cricketMatchesSocket.on("CricketOddMatch_FromAPI", (data) => {
      // console.log("Label socket after",data)
      setSocketData(data);
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);
  useEffect(() => {
    if (socketData && socketData.length > 0) {
      setFilterMarket(socketData, "Match Odds", setcountrydata);
      setFilterMarket(socketData, "Tied Match", setTiedmarkId, setTiedlabel);
      setFilterMarket(
        socketData,
        "Completed Match",
        setComplmarkId,
        setComplabel
      );
    }
  }, [socketData]);
  const setFilterMarket = (
    res,
    condition,
    setStateFunction,
    setlabelFunction
  ) => {
    if (Array.isArray(res)) {
      let fiteredData = res.filter((ele) => ele.marketName === condition);
      if (condition === "Match Odds") {
        setStateFunction(fiteredData[0]?.runners);
      } else {
        setStateFunction(fiteredData[0]?.marketId);
        setlabelFunction(fiteredData[0]?.runners);
      }
    } else {
      console.error("Error: Input is not an array");
    }
  };
  const SaveTeams_API = async (mname, team) => {
    dispatch(saveTeams({id, mname, team, token}));
  };
  useEffect(() => {
    if (counteyName && counteyName.length > 0 && count < 1) {
      counteyName.forEach((runner) => {
        SaveTeams_API("Match Odds", runner.runnerName);
        SaveTeams_API("Bookmaker Market", runner.runnerName);
      });
      setCount(count + 1);
    }
    if (Tiedlabel && Tiedlabel.length > 0 && TiedCount < 1) {
      Tiedlabel.forEach((runner) => {
        SaveTeams_API("Tied Match", runner.runnerName);
      });
      setTiedCount(count + 1);
    }
    if (Complabel && Complabel.length > 0 && CompCount < 1) {
      Complabel.forEach((runner) => {
        SaveTeams_API("Completed Match", runner.runnerName);
      });
      setCompCount(count + 1);
    }
  }, [counteyName]);
  const [modal, setModal] = useState(false);
  const [marketType, setmarketType] = useState(null);
  const [betType, setbetType] = useState(null);
  const [betDetails, setbetDetails] = useState({});
  const [countryName, setcountryName] = useState(null);
  const [colrs,setColrs] = useState();
  const dispatch = useDispatch();
  const handleModal = () => {
    setModal(!modal);
  };
  // console.log("Background Color : ",colrs)
  return (
    <div className="row  ">
      {/* <div className="betting-info">
        <button className="betting-info-btn px-2 bg-dark">OPEN MARKET</button>
        <button className="betting-info-btn px-2 bg-dark">ODDS</button>
        <button className="betting-info-btn px-2 bg-dark">BOOKMAKER</button>
        <button className="betting-info-btn px-2 bg-dark">FANCY</button>
      </div> */}
      <MatchOdds
        counteyName={counteyName}
        handleModal={handleModal}
        setmarketType={setmarketType}
        setbetType={setbetType}
        setbetDetails={setbetDetails}
        setRealtimeData={setRealtimeData}
        marketType={marketType}
        betType={betType}
        realtimeData={realtimeData}
        setColrs={setColrs}
      />
      <BookMaker
        handleModal={handleModal}
        setmarketType={setmarketType}
        setbetType={setbetType}
        setbetDetails={setbetDetails}
        setRealtimeData={setRealtimeData}
        marketType={marketType}
        betType={betType}
        realtimeData={realtimeData}
        setColrs={setColrs}
      />
      <Completed counteyName={Complabel} ComplmarkId={ComplmarkId} />
      <Session
        setbetDetails={setbetDetails}
        setmarketType={setmarketType}
        setbetType={setbetType}
        handleModal={handleModal}
        setcountryName={setcountryName}
        setrealtimeData={setRealtimeData}
        marketType={marketType}
        realtimeData={realtimeData}
        betType={betType}
        setColrs={setColrs}
      />
      <TiedMatch
        handleModal={handleModal}
        setmarketType={setmarketType}
        setbetType={setbetType}
        setbetDetails={setbetDetails}
        setRealtimeData={setRealtimeData}
        marketType={marketType}
        betType={betType}
        realtimeData={realtimeData}
        setColrs={setColrs}
      />
      {/* <Fancy /> */}
      {modal && (
        <SportModal
          modal={modal}
          setModal={setModal}
          setbetType={setbetType}
          setmarketType={setmarketType}
          setbetDetails={setbetDetails}
          betDetails={betDetails}
          betType={betType}
          marketType={marketType}
          countryName={countryName}
          realtimeData={realtimeData}
          setRealtimeData={setRealtimeData}
          colrs={colrs}
        />
      )}
    </div>
  );
};
export default OpenMarkeet;
